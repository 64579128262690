.fourohfour {
	position: relative;
	background-color: $teal;
	.fourohfour__gradient {
		content: '';
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background: rgb(0,19,135); /* Old browsers */
		background: -moz-linear-gradient(left, rgba(0,19,135,1) 0%, rgba(14,129,125,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(left, rgba(0,19,135,1) 0%,rgba(14,129,125,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to right, rgba(0,19,135,1) 0%,rgba(14,129,125,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001387', endColorstr='#0e817d',GradientType=1 ); /* IE6-9 */
	}
	.fourohfour__dots {
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		opacity: 0.1;
		display: none;
		@include bp(sm) {
			display: block;
		}
		&:before {
			position: absolute;
			content: '';
			top: 0;
			left: 0;
			height: 100%;
			width: 50%;
			background-image: url('../img/dot_background-left.png');
			background-repeat: no-repeat;
		}
		&:after {
			position: absolute;
			content: '';
			top: 0;
			right: 0;
			height: 100%;
			width: 50%;
			background-image: url('../img/dot_background.png');
			background-position: right top;
			background-repeat: no-repeat;
		}
	}

	.fourohfour__icon {
		text-align: center;
		margin: 76px auto 20px;
		@include bp(sm) {
			margin: 122px auto 70px;
		}
		img {
			max-height: 126px;
			margin: 0 auto;
			@include bp(sm) {
				max-height: 270px;
			}
		}
	}

	.fourohfour__body {
		p {
			color: white;
			font-size: 24px;
			text-align: center;
			margin: 0 auto 44px;
			font-size: 24px;
			line-height: 29px;
			font-weight: 300;
			@include bp(sm) {
				font-size: 36px;
				line-height: 44px;
				margin: 0 auto 42px;
			}
		}
	}

	.fourohfour__search {
		position: relative;
		margin: 0 0 106px;
		color: #000;
		@include bp(sm) {
			margin: 0 0 150px;
		}
		.search__field {
			height: 52px;
		    width: 100%;
			position: relative;
			top: 1px;
		    padding: 13px 20px;

		    &::-moz-placeholder {
				font-size: 16px;
				line-height: 24px;
				color: #9b9b9b;
				font-style: italic;
				opacity: 1;
				font-weight: 300;
			}
			&:-ms-input-placeholder {
				font-size: 16px;
				line-height: 24px;
				color: #9b9b9b;
				font-style: italic;
				opacity: 1;
				font-weight: 300;
			}
			&::-webkit-input-placeholder { 
				font-size: 16px;
				line-height: 24px;
				color: #9b9b9b;
				font-style: italic;
				opacity: 1;
				font-weight: 300;
			}
		}
		.fa-search {
    		color: $blue;
    		position: absolute;
		    top: 20px;
			right: 17px;
			&:after {
				content: '';
				position: absolute;
				top: -5px;
				left: -12px;
				height: 24px;
				width: 2px;
				background: #eee;
			}
    	}
	}
}