// Text colors
$primary-color: #333333;
$secondary-color: #999999;
$text-color: #4a4a4a;
$text-color-dark: darken($text-color, 15%);
$input-color-placeholder: #AAA;

// Typography
$font-main: 'Lato', sans-serif;
$line-height-base: 1.42857143;
$font-size-base: 14px;
$line-height-computed: floor(($font-size-base * $line-height-base));

$transition-duration: 0.15s;

// Background colors
$background-dark: #404040; // Dark gray
$background-light: #EEEEEE; // Light gray

// Theme colors
$blue: #001387;
$light-blue: #4653A8;
$blue-light: $light-blue;

$teal: #0E817D;
$teal-light: #119B96;
$teal-dark: #0B615E;

$gold: #EDAA0E;
$gold-light: #FFB70F;
$gold-dark: #DE9F0D;

$all-t: 0.3s all ease;

// Buttons
$btn-padding: '14px 22px 13px 23px';
$btn-color: #cccccc;
$btn-border-color: #c0c0c0;
$btn-icon-margin: 10px; // margin between icon and text
$btn-border-radius-base: 0;

// Breakpoints & structure
$screen-xs-min: 1px;
$screen-sm-min: 768px;
$screen-md-min: 992px;
$screen-lg-min: 1200px;
$screen-xl-min: 1440px; 
$grid-gutter-width: 30px;
$base-padding: ($grid-gutter-width / 2);