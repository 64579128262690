.department {
	.page__hero{
		background: $gold-light;
		.page-title {
			color: $blue;
			margin: 40px 0 0;
			&:after {
				content: '';
				display: block;
				margin: 24px 0 18px;
				width: 100px;
				height: 1px;
				background: $gold-dark;
			}
			@include bp(md) {
				margin: 60px 0 82px;
				&:after {
					display: none;
				}
			}
			@include bp(lg) {
				margin: 60px 0 72px;
			}
		}
		.page-subtitle {
			color: $blue;
			font-size: 20px;
			line-height: 30px;
			margin-bottom: 32px;
			@include bp(sm) {
				margin-bottom: 42px;
			}
			@include bp(md) {
				margin: 62px 0 26px;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					height: 100%;
					width: 1px;
					background: $gold-dark;
					left: -20px;
					top: 0;
				}
			}
			@include bp(lg) {
				margin: 62px 0 52px;
			}
		}
		.page__hero__dots {
			z-index: 0;
		}
		.department__img-row {
			overflow: hidden;
			position: relative;
			.department__img-row__img {
				display: none;
				height: 240px;
				&.img--1 {
					display: block;
					border-top: 8px $gold-dark solid;
				}
				.dpt-hero-img {
					height: 100%;
					width: 100%;
				}
				@include bp(sm) {
					display: block;
					padding: 0;
					height: 230px;
					position: relative;
					top: 40px;
					&.img--1 {
						border-right: 8px $gold-dark solid;
						height: 270px;
						top: 0;
					}
				}
				@include bp(md) {
					&.img--1 {
						border-top: 10px $gold-dark solid;
						border-right: 10px $gold-dark solid;
					}
				}
				@include bp(lg) {
					height: 296px;
					top: 50px;
					&.img--1 {
						height: 346px;
						border-top: 12px $gold-dark solid;
						border-right: 12px $gold-dark solid;
						top: 0;
					}
				}
			}
		}
	}
	.page__content {
		.areas-of-study {
			margin-bottom: 60px;
			@include bp(sm) {
				margin-bottom: 46px;
			}
			@include bp(md) {
				margin-bottom: 24px;
			}
			> h2 {
				margin: 44px 0;
			}
			.as-row {
			    .area-link {
			    	display: block;
			    	position: relative;
			    	a {
			    		font-size: 20px;
			    		line-height: 30px;
			    		font-weight: 400;
			    		display: block;
			    		padding: 0 58px 8px 0;
			    		margin: 14px 0;
			    		border-bottom: 4px solid #ddd;
			    		transition: $all-t;
			    		&:hover {
			    			border-bottom: 4px solid #eee;
			    			.far {
			    				color: $teal-dark;
			    				right: 0px;
			    			}
			    		}
			    	}
			    	.far {
			    		position: absolute;
			    		top: calc(50% - 12px);
			    		right: 22px;
			    		font-size: 18px;
			    		color: $teal;
			    		transition: $all-t;
			    	}
			    	@include bp(sm) {
			    		display: inline-block;
			    		width: calc(50% - 11px);
			    		.far {
			    			right: 8px;
			    		}
			    		&.al-1 {
				    		margin-right: 18px;
				    	}
			    	}
			    }
			}
		}
	}
	.department__info {
		background: $teal;
		color: white;
		padding: 50px 0 12px;
		margin-top: -6px;
		@include bp(sm) {
			margin-top: 0;
			padding: 44px 0 46px;
		}
		@include bp(md) {
			padding: 45px 0;
			margin-top: 48px;
		}
		@include bp(lg) {
			padding: 19px 0 18px;
		}
		.department__info__entry {
			border-top: 4px solid $teal-light;
			padding: 20px 0 40px;
			@include bp(sm) {
				border-top: 0;
				padding: 20px 0 20px 26px;
				min-height: 210px;
				position: relative;
				&:before {
					content: '';
					position: absolute;
					left: 0;
					top: 0;
					height: 100%;
					width: 4px;
					background: $teal-light;
				}
			}
			@include bp(md) {
				padding: 15px 0 15px 28px;
				min-height: 190px;
			}
			@include bp(lg) {
				min-height: 243px;
				padding: 41px 0 48px 38px;
			}
			.department__info__entry__title {
				display: block;
				text-transform: uppercase;
				font-size: 16px;
				line-height: 19px;
				letter-spacing: 3px;
				font-weight: 400;
				margin-bottom: 10px;
			}
			.department__info__entry__body {
				font-size: 16px;
				line-height: 24px;
				font-weight: 400;
				margin-bottom: 24px;
			}
			.btn--gold {
				font-weight: 600;
				@include bp(sm) {
					position: absolute;
					bottom: 20px;
					left: 26px;
				}
				@include bp(md) {
					left: 30px;
					bottom: 15px;
				}
				@include bp(lg) {
					left: 38px;
					bottom: 48px;
				}
			}
		}
	}
}