// _mixins.scss
$screen-xs-max: 767px;
$screen-sm-max: 991px;

// Breakpoint mixin
@mixin bp($point) {
	@if $point == xs {
		@media only screen and (min-width: $screen-xs-min) { 
			@content; 
		}
	}
	@else if $point == sm {
		@media only screen and (min-width: $screen-sm-min) { 
			@content; 
		}
	}
	@else if $point == md {
		@media only screen and (min-width: $screen-md-min) { 
			@content; 
		}
	}
	@else if $point == lg {
		@media only screen and (min-width: $screen-lg-min) { 
			@content;
		}
	}
	@else if $point == xl {
		@media only screen and (min-width: $screen-xl-min) { 
			@content;
		}
	}
	@else {
		@media only screen and (unquote($point)) { 
			@content; 
		}
	}
}
// Variant of above used to select only one breakpoint rather than "this and up"
@mixin bp-only($point) {
	@if $point == xs {
		@media only screen and (min-width: $screen-xs-min) and (max-width: $screen-xs-max) { 
			@content; 
		}
	}
	@else if $point == sm {
		@media only screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) { 
			@content; 
		}
	}
	@else if $point == md {
		@media only screen and (min-width: $screen-md-min) and (max-width: $screen-md-max) { 
			@content; 
		}
	}
	@else if $point == lg {
		@media only screen and (min-width: $screen-lg-min) and (max-width: $screen-lg-max) { 
			@content;
		}
	}
	@else if $point == xl {
		@media only screen and (min-width: $screen-xl-min) { 
			@content;
		}
	}
}

// Animations
@mixin animation($animation) {
	-webkit-animation: $animation;
		 -o-animation: $animation;
			animation: $animation;
}
@mixin animation-name($name) {
	-webkit-animation-name: $name;
			animation-name: $name;
}
@mixin animation-duration($duration) {
	-webkit-animation-duration: $duration;
			animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
	-webkit-animation-timing-function: $timing-function;
			animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
	-webkit-animation-delay: $delay;
			animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
	-webkit-animation-iteration-count: $iteration-count;
			animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
	-webkit-animation-direction: $direction;
			animation-direction: $direction;
}
@mixin animation-fill-mode($fill-mode) {
	-webkit-animation-fill-mode: $fill-mode;
			animation-fill-mode: $fill-mode;
}

// Backface visibility
// Prevent browsers from flickering when using CSS 3D transforms.
// Default value is `visible`, but can be changed to `hidden`
@mixin backface-visibility($visibility) {
	-webkit-backface-visibility: $visibility;
		 -moz-backface-visibility: $visibility;
			backface-visibility: $visibility;
}

// Box sizing
@mixin box-sizing($boxmodel) {
	-webkit-box-sizing: $boxmodel;
		 -moz-box-sizing: $boxmodel;
			box-sizing: $boxmodel;
}

// CSS3 Content Columns
@mixin content-columns($column-count, $column-gap: $grid-gutter-width) {
	-webkit-column-count: $column-count;
		 -moz-column-count: $column-count;
			column-count: $column-count;
		-webkit-column-gap: $column-gap;
			 -moz-column-gap: $column-gap;
				column-gap: $column-gap;
}

// Optional hyphenation
@mixin hyphens($mode: auto) {
	word-wrap: break-word;
	-webkit-hyphens: $mode;
		 -moz-hyphens: $mode;
		-ms-hyphens: $mode; // IE10+
		 -o-hyphens: $mode;
			hyphens: $mode;
}

// Placeholder text
@mixin placeholder($color: $input-color-placeholder) {
	// Firefox
	&::-moz-placeholder {
		color: $color;
		opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
	}
	// Internet Explorer 10+
	&:-ms-input-placeholder {
		color: $color; 
	}
	// Safari and Chrome
	&::-webkit-input-placeholder { 
		color: $color; 
	}
}

// Retina background images (use a 2x image for this)
@mixin image-2x($image, $width, $height) {
	@media (-moz-min-device-pixel-ratio: 1.3),
			 (-o-min-device-pixel-ratio: 2.6/2),
			 (-webkit-min-device-pixel-ratio: 1.3),
			 (min-device-pixel-ratio: 1.3),
			 (min-resolution: 1.3dppx) {
		background-image: url($image);
		background-size: $width $height;
	}
}

// Transformations
@mixin scale($ratio...) {
	-webkit-transform: scale($ratio);
		-ms-transform: scale($ratio); // IE9 only
		 -o-transform: scale($ratio);
			transform: scale($ratio);
}

@mixin scaleX($ratio) {
	-webkit-transform: scaleX($ratio);
		-ms-transform: scaleX($ratio); // IE9 only
		 -o-transform: scaleX($ratio);
			transform: scaleX($ratio);
}
@mixin scaleY($ratio) {
	-webkit-transform: scaleY($ratio);
		-ms-transform: scaleY($ratio); // IE9 only
		 -o-transform: scaleY($ratio);
			transform: scaleY($ratio);
}
@mixin skew($x, $y) {
	-webkit-transform: skewX($x) skewY($y);
		-ms-transform: skewX($x) skewY($y); // See https://github.com/twbs/bootstrap/issues/4885; IE9+
		 -o-transform: skewX($x) skewY($y);
			transform: skewX($x) skewY($y);
}
@mixin translate($x, $y) {
	-webkit-transform: translate($x, $y);
		-ms-transform: translate($x, $y); // IE9 only
		 -o-transform: translate($x, $y);
			transform: translate($x, $y);
}
@mixin translate3d($x, $y, $z) {
	-webkit-transform: translate3d($x, $y, $z);
			transform: translate3d($x, $y, $z);
}
@mixin rotate($degrees) {
	-webkit-transform: rotate($degrees);
		-ms-transform: rotate($degrees); // IE9 only
		 -o-transform: rotate($degrees);
			transform: rotate($degrees);
}
@mixin rotateX($degrees) {
	-webkit-transform: rotateX($degrees);
		-ms-transform: rotateX($degrees); // IE9 only
		 -o-transform: rotateX($degrees);
			transform: rotateX($degrees);
}
@mixin rotateY($degrees) {
	-webkit-transform: rotateY($degrees);
		-ms-transform: rotateY($degrees); // IE9 only
		 -o-transform: rotateY($degrees);
			transform: rotateY($degrees);
}
@mixin perspective($perspective) {
	-webkit-perspective: $perspective;
		 -moz-perspective: $perspective;
			perspective: $perspective;
}
@mixin perspective-origin($perspective) {
	-webkit-perspective-origin: $perspective;
		 -moz-perspective-origin: $perspective;
			perspective-origin: $perspective;
}
@mixin transform-origin($origin) {
	-webkit-transform-origin: $origin;
		 -moz-transform-origin: $origin;
		-ms-transform-origin: $origin; // IE9 only
			transform-origin: $origin;
}


// Transitions
@mixin transition($transition...) {
	-webkit-transition: $transition;
		 -o-transition: $transition;
			transition: $transition;
}
@mixin transition-property($transition-property...) {
	-webkit-transition-property: $transition-property;
			transition-property: $transition-property;
}
@mixin transition-delay($transition-delay) {
	-webkit-transition-delay: $transition-delay;
			transition-delay: $transition-delay;
}
@mixin transition-duration($transition-duration...) {
	-webkit-transition-duration: $transition-duration;
			transition-duration: $transition-duration;
}
@mixin transition-timing-function($timing-function) {
	-webkit-transition-timing-function: $timing-function;
			transition-timing-function: $timing-function;
}
@mixin transition-transform($transition...) {
	-webkit-transition: -webkit-transform $transition;
		 -moz-transition: -moz-transform $transition;
		 -o-transition: -o-transform $transition;
			transition: transform $transition;
}

// User select
// For selecting text on the page
@mixin user-select($select) {
	-webkit-user-select: $select;
		 -moz-user-select: $select;
		-ms-user-select: $select; // IE10+
			user-select: $select;
}

@mixin button-variant($color, $background, $border) {
	color: $color;
	background-color: $background;
	border-color: $border;

	&:focus,
	&.focus {
		color: $color;
		background-color: darken($background, 10%);
		border-color: darken($border, 25%);
	}
	&:hover {
		color: $color;
		background-color: darken($background, 10%);
		border-color: darken($border, 12%);
	}
	&:active,
	&.active {
		color: $color;
		background-color: darken($background, 10%);
		border-color: darken($border, 12%);

		&:hover,
		&:focus,
		&.focus {
			color: $color;
			background-color: darken($background, 17%);
			border-color: darken($border, 25%);
		}
	}
	&:active,
	&.active {
		background-image: none;
	}
	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		&:hover,
		&:focus,
		&.focus {
			background-color: $background;
			border-color: $border;
		}
	}
}

// Button sizes
@mixin button-size($padding, $font-size, $line-height, $border-radius) {
	padding: unquote($padding);
	font-size: $font-size;
	line-height: $line-height;
	border-radius: $border-radius;
}

@mixin tab-focus() {
	// Default
	outline: thin dotted;
	// Webkit
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

// Responsive show/hide
@mixin responsive-visibility($parent) {
	#{$parent} {
		display: block !important;
	}
	table#{$parent}  { display: table !important; }
	tr#{$parent}     { display: table-row !important; }
	th#{$parent},
	td#{$parent}     { display: table-cell !important; }
}

@mixin responsive-invisibility($parent) {
	#{$parent} {
		display: none !important;
	}
}

// Manually initialize FontAwesome (e.g. in a :before block)
@mixin fa($char) {
	content: $char;
	font: normal normal normal 14px/1 'FontAwesome';
	font-size: inherit;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin fa-spin() {
	-webkit-animation: fa-spin 2s infinite linear;
	animation: fa-spin 2s infinite linear;
}

@mixin fa-pulse() {
	-webkit-animation: fa-spin 1s infinite steps(8);
	animation: fa-spin 1s infinite steps(8);
}

@mixin fa-fw() {
	width: 1.28571429em;
	text-align: center;
}