header {
	background: #eee;
	.alert {
		background: #95210C;
		color: white;
		height: 60px;
		padding: 18px 0;
		font-size: 14px;
		line-height: 22px;
		display: none;
		@include bp(sm) {
			display: block;
		}
		.alert__title {
			text-transform: uppercase;
			font-weight: 700;
			position: relative;
			margin-right: 24px;
			z-index: 2;
			&:before {
		        content: '';
			    background-image: url(../img/exclamation-triangle.png);
			    height: 60px;
			    width: 80px;
			    position: absolute;
			    background-size: cover;
			    background-position: center;
			    top: -20px;
			    left: -42px;
			    z-index: -1;
			}
			&:after {
				position: absolute;
				top: -6px;
				right: -14px;
				content: '';
				height: 30px;
				width: 1px;
				display: inline-block;
				background: #F3492B;
			}
		}
		.far {
		    font-size: 18px;
    		float: right;
    		@include bp(sm) {
    			position: relative;
    			top: 2px;
    		}
		}
	}
	.branding {
		.logo {
			margin: 26px 0 20px;
			@include bp(sm) {
				margin: 25px 0 15px;
			}
			@include bp(md) {
				display: inline-block;
				float: left;
			}
			img {
				height: 54px;
				width: auto;
				@include bp(sm) {
					height: 60px;
				}
			}
		}
		.navigation {
			display: inline;
			.navigation__inner {
				display: inline;
			}
		}
	}

	.navigation {
		overflow: hidden;
		.main-menu__items {
		    position: fixed;
		    top: 0;
		    right: -230px;
		    width: 230px;
		    height: 100%;
		    background: white;
		    z-index: 30;
		    overflow: auto;
		    @include bp-only(sm) {
		    	&:before {
				    position: absolute;
				    content: '';
				    top: 0;
				    left: 0;
				    width: 100%;
				    height: 10000px;
				    z-index: -1;
				    background: white;
				}
		    }
		    @include bp(md) {
		    	overflow: visible;
		    	position: relative;
		    	top: auto;
		    	right: auto !important;
		    	background: transparent;
		    	width: auto;
		    	z-index: 10;
		    }
		}
	}
	.translate {
		background: $teal;
		height: 50px;
		position: relative;
		.header__links {
			display: none;
			color: white;
			@include bp(sm) {
				display: inline;
				float: left;
			}
			.header__links__link {
				display: inline-block;
				height: 40px;
				padding-top: 17px;
				font-size: 14px;
				line-height: 17px;
				transition: $all-t;
				color: white;
				font-weight: 400;
				&:nth-child(2) {
					margin: 0 20px;
				}
				&:after {
					content: '';
					display: block;
					height: 1px;
					width: 0;
					position: relative;
					bottom: -1px;
					background: #fff;
					transition: width 0.2s ease;
				}
				&:hover, &:focus, &:active {
					outline: none;
					&:hover:after, &:focus:after {
						width: 100%;
					}
				}
			}
		}
		.header__search {
			background: $blue;
			color: white;
			float: right;
			height: 50px;
			width: 50px;
			transition: $all-t;
			&:hover {
				cursor: pointer;
				background: darken($blue, 15%);
			}
			.far {
				font-size: 16px;
				line-height: 16px;
				position: relative;
				top: 15px;
				left: 17px;
			}
		}
		.search--active {
			.header__search {
				.far:before {
					content: '\f00d';
				    font-size: 20px;
				    position: relative;
				    top: 4px;
				    left: 1px;
				}
			}
		}
		.header__search--bar {
		    position: absolute;
		    z-index: 22;
		    top: 50px;
		    left: 0;
		    width: 100%;
		    height: 80px;
		    background: $blue;
		    .search__block {
		    	margin: 20px auto;
		    	display: block;
		    	position: relative;
		    	color: white;
		    	.search__field {
		    		padding: 9px 16px 9px 60px;
		    		border: 1px #ddd solid;
		    		border-radius: 2px;
	    		    margin: 0 15px;
					display: block;
					width: calc(100% - 30px);
					@include bp(sm) {
						width: 480px;
						margin: 0 auto;
					}
					&::-moz-placeholder {
						font-size: 16px;
						font-weight: 300;
						color: #9b9b9b;
						font-style: italic;
						opacity: 1;
					}
					&:-ms-input-placeholder {
						font-size: 16px;
						font-weight: 300;
						color: #9b9b9b;
						font-style: italic;
						opacity: 1;
					}
					&::-webkit-input-placeholder { 
						font-size: 16px;
						font-weight: 300;
						color: #9b9b9b;
						font-style: italic;
						opacity: 1;
					}
					
		    	}
		    	.fa-search {
		    		color: $blue;
		    		position: absolute;
	    		    top: 12px;
    				left: 30px;
    				@include bp(sm) {
    					left: calc(50% - 225px);
    				}
    				&:after {
    					content: '';
    					position: absolute;
    					top: -5px;
    					right: -12px;
    					height: 24px;
    					width: 2px;
    					background: #eee;
    				}
		    	}
		    }
		}
		.header__login {
			float: right;
			height: 50px;
			width: 92px;
			background: $gold-light;
			color: $blue;
			.header__login__title {
				text-align: center;
				font-size: 14px;
				line-height: 17px;
				position: relative;
				padding-top: 14px;
				height: 100%;
				.far {
					margin-left: 8px;
					margin-top: 4px;
					transition: $all-t;
				}
				&:hover {
					cursor: pointer;
				}
			}
			&.header__login--active {
				.header__login__title .far {
					transform: rotate(180deg);
				}
				.header__login__list .header__login__list__entry {
					> a {
						display: block;
					}
				}
			}
			.header__login__list {
				background: $gold-light;
				width: 190px;
				max-height: 160px;
				overflow: auto;
				position: relative;
				right: 98px;
				z-index: 22;
				.header__login__list__entry {
					
					&:first-child a {
						border: 0;
					}
					> a {
						font-size: 12px;
						line-height: 18px;
						color: $blue;
						font-weight: 400;
						padding: 10px 45px 10px 10px;
						transition: $all-t;
						position: relative;
						border-top: 1px solid $gold-dark;
						display: none;
						.far {
							position: absolute;
							top: 12px;
							right: 18px;
							transition: 0.3s right ease;
						}
						&:hover, &:focus {
							color: white;
							background: $blue;
							cursor: pointer;
							outline: none;
							
							.far {
								right: 13px;
								color: white;
							}
						}
					}
				}
			}
		}
		.header__translate {
			float: right;
			height: 50px;
			width: 118px;
			background: $teal-dark;
			color: white;
			.header__translate__title {
				text-align: center;
				font-size: 14px;
				line-height: 17px;
				position: relative;
				padding-top: 14px;
				height: 100%;
				.far {
					margin-left: 8px;
					margin-top: 4px;
					transition: $all-t;
				}
				&:hover {
					cursor: pointer;
				}
			}
			&.header__translate--active {
				.header__translate__title .far {
					transform: rotate(180deg);
				}
				.header__translate__list .header__translate__list__entry {
					> a {
						display: block;
					}
				}
			}
			.header__translate__list {
				background: $teal-dark;
				width: 170px;
				max-height: 240px;
				overflow: auto;
				position: relative;
				right: 52px;
				z-index: 22;
				.header__translate__list__entry {
					> a {
						color: white;
						font-size: 12px;
						line-height: 18px;
						font-weight: 400;
						padding: 4px 45px 4px 15px;
						transition: $all-t;
						display: none;
						outline: none;
						&:hover, &:focus {
							background: $teal;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}