#jb-search-results .gsc-control-cse,
#jb-search-results .gsc-thumbnail-inside,
#jb-search-results .gsc-table-result,
#jb-search-results .gsc-url-top { padding: 0; }
#jb-search-results .gsc-result-info { padding: 0; margin-bottom: 4px; }
#jb-search-results div.gs-title { margin-bottom: 4px; }
#jb-search-results .gsc-control-cse,
#jb-search-results .gsc-control-cse .gsc-table-result { font-family: inherit; }
#jb-search-results .gsc-control-cse table { margin-bottom: 0; border: 0; }
#jb-search-results .gsc-control-cse table td {
	background: transparent none;
	padding: 0;
	border: none;
}
#jb-search-results .gsc-result .gs-title { overflow: visible; }
#jb-search-results .gsc-control-cse * { text-decoration: none !important; }
#jb-search-results .gsc-control-cse .gsc-result img { border: 0; }
#jb-search-results .gcsc-branding { display: none; }
#jb-search-results .gsc-selected-option-container,
#jb-search-results .gsc-selected-option-container * {
	-webkit-box-sizing: content-box;
	   -moz-box-sizing: content-box;
	        box-sizing: content-box;
}