// WordPress required classes
.aligncenter, 
div.aligncenter { 
	display: block; 
	margin-right: auto; 
	margin-left: auto; 
}
.alignleft { 
	float: left; 
}
img.alignleft, 
.wp-caption.alignleft { 
	margin: 0 $base-padding $base-padding 0; 
}
img.alignright,
.wp-caption.alignright { 
	margin: 0 0 $base-padding $base-padding; 
}
.alignright { 
	float: right; 
}
img.alignnone { 
	margin-bottom: $base-padding; 
}
