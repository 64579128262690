// UI buttons (adapted from Bootstrap but customized per site)

.btn {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: none;
	border-bottom: 0;
	@include button-size($btn-padding, $font-size-base, $line-height-base, 2px);
	@include user-select(none);
	@include transition(all $transition-duration);
	box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.15);
	font-size: 14px;
	line-height: 17px;
	&,
	&:active,
	&.active {
		&:focus,
		&.focus {
			@include tab-focus;
		}
	}

	&:hover,
	&:focus,
	&.focus {
		color: $text-color;
		text-decoration: none;
		box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.075);
	}

	&:active,
	&.active {
		outline: 0;
		background-image: none;
		box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.075);
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
		opacity: 0.65;
		box-shadow: none;
	}
	@include bp(sm) {
		white-space: nowrap;
	}
}
a.btn {
	&.disabled,
	fieldset[disabled] & {
		pointer-events: none;
	}
}
.btn--primary {
	@include button-variant(#FFFFFF, $btn-color, $btn-border-color);
}
.btn--white {
	@include button-variant($blue, white, white);
	&:hover {
		background-color: #ddd;
		border-color: #ddd;
	}
}
.btn--gold {
	@include button-variant($blue, $gold-light, $gold-light);
	&:hover {
		background-color: $gold;
		border-color: $gold;
	}
}
.btn--blue {
	@include button-variant(white, $blue, $blue);
	&:hover {
		background-color: #000e65;
		border-color: #000e65;
	}
}
.btn--teal {
	@include button-variant(white, $teal, $teal);
	padding: 11px 12px 11px 14px;
	font-size: 11px;
	line-height: 13px;
	&:hover {
		background-color: $teal-dark;
		border-color: $teal-dark;
	}
}
.btn--teal--dark {
	@include button-variant(white, $teal-dark, $teal-dark);
	padding: 11px 12px 11px 14px;
}
.btn--link {
	font-weight: 600;
	padding: 0;
	border: none;
	color: $primary-color;
	font-size: 15px;
	background: none;
	&:hover,
	&:focus,
	&:active {
		color: lighten($primary-color, 23%);
	}
}
.btn--arrow-before {
	&:before {
		@include fa("\f0a9");
		margin-right: $btn-icon-margin;
	}
	&.btn--link:before {
		margin-right: ($btn-icon-margin - 2);
	}
}
.btn--arrow-after {
	&:after {
		@include fa("\f0a9");
		margin-left: $btn-icon-margin;
	}
	&.btn--link:after {
		margin-left: ($btn-icon-margin - 2);
	}
}