// Sitewide Variables
@import 'components/variables';

// Responsive framework
@import 'framework/mixins'; // Core mixins
@import 'framework/normalize'; // Normalize
@import 'framework/grid'; // Grid (adapted from Bootstrap)
@import 'framework/responsive-utilities'; // Responsive Utilities (adapted from Bootstrap)

// Base styles, utility classes, etc.
@import 'components/base'; // Base styles
@import 'components/buttons'; // All UI buttons used throughout the site
@import 'layout/wp'; // WordPress-required styles

@import 'layout/sidenav'; // Sidebar navigation with base styles as examples

@import 'vendors/responsive-tables'; // Responsive Tables styles (works with responsive-tables.js)
@import 'vendors/smartmenus'; // SmartMenus required styles & customization
@import 'vendors/slick-theme';
@import 'vendors/slick';

@import 'fa-pro-scss/fontawesome';

/* SITE-SPECIFIC STYLES GO HERE */
@import 'layout/front-page';
@import 'layout/interior';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/department';
@import 'layout/news';
@import 'layout/classes';
@import 'layout/fourohfour';

// Rich-text editable area styles
.rte {
	line-height: $line-height-base;
	& > :first-child { margin-top: 0 !important; }
	& > :last-child { margin-bottom: 0 !important; }
	@import 'layout/content';
}

@import 'vendors/gravity-forms'; // Apply Bootstrap styles to Gravity Forms
@import 'vendors/google-search'; // Google search results (resets)
@import 'components/misc'; // Miscellaneous tail-end stuff
