html, button, input, select, textarea { color: #222; }
body { font-size: 1em; line-height: 1.4; }

hr { 
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
	clear: both;
}
img { vertical-align: middle; }
fieldset { border: 0; margin: 0; padding: 0; }
textarea { resize: vertical; }

/* DEFAULT PRINT STYLES */
@media print {
	* {
		background: transparent !important;
		color: #000 !important; /* Black prints faster */
		box-shadow: none !important;
		text-shadow: none !important;
	}
	.rte a, a:visited { text-decoration: underline; }
	.rte a[href]:after { content: " (" attr(href) ")"; } /* Show the URL after a link when printed */

	.rte .ir a:after, /* Don't show links for images, or javascript/internal links */
	.rte a[href^="javascript:"]:after,
	.rte a[href^="#"]:after { content: ""; }
	pre, blockquote { border: 1px solid #999; page-break-inside: avoid; }
	thead { display: table-header-group; }
	tr, img { page-break-inside: avoid;	}
	img { max-width: 100% !important;	}
	@page { margin: 0.5cm; }
	p, h2, h3 { orphans: 3; widows: 3; }
	h2, h3 { page-break-after: avoid; }
}

* {
	box-sizing: border-box;
}
*:before,
*:after {
	box-sizing: border-box;
}
html {
	font-size: 10px;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
	font-size: $font-size-base;
	font-family: $font-main;
	line-height: $line-height-base;
	color: $text-color;
	background: white;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p, table { margin-top: 0; }
h1, h2, h3, h4, h5, h6 { 
	line-height: 1em; 
	margin-top: 22px;
	margin-bottom: 14px; 
}
ul, ol { margin: 0; padding: 0; list-style: none; }
.rte ul { margin-bottom: $base-padding; padding-left: ($base-padding * 1.5); list-style-type: disc; }
.rte ol { margin-bottom: $base-padding; padding-left: ($base-padding * 1.5); list-style-type: decimal; }

a {
	color: $blue;
	text-decoration: none;
	font-weight: 700;
	font-size: 16px;
	@include transition(color $transition-duration);
	.fa-long-arrow-alt-right {
		color: $gold;
		margin-left: 8px;
	}
	&.teal-link {
		color: $teal;
	}
	&.arrow-link {
		display: block;
		margin: 8px 0;
		letter-spacing: 0.5px;
	}
}
p {
	margin-top: 0;
}
a:hover, a:focus { 
	text-decoration: none; 
	color: darken($primary-color, 12%);
}
strong {
	font-weight: 600;
	color: $text-color-dark;
}
input,
button,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
figure {
	margin: 0;
}
img {
	vertical-align: middle;
	max-width: 100%;
}
hr {
	margin-top: 20px;
	margin-bottom: 20px;
	border-top-color: #eeeeee;
}
.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	border: 0;
}
.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	margin: 0;
	overflow: visible;
	clip: auto;
}
[role="button"] {
	cursor: pointer;
}

::-moz-selection {
	text-shadow: none;
	background-color: $secondary-color;
	color: $text-color-dark;
}
::selection {
	text-shadow: none;
	background-color: $secondary-color;
	color: $text-color-dark;
}

.clear { 
	display: block; 
	clear: both; 
	width: 100%; 
	height: 1px; 
	margin: 0 0 -1px; 
}

@include bp(sm) {
	html, body { height: 100%; }
}

.embed-wrap { 
	position: relative; 
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
	height: auto;
} 
.embed-wrap iframe,
.embed-wrap object,
.embed-wrap embed { 
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
}

@include placeholder;

#jb-skip-link {
	position: absolute;
	top: 0;
	left: -9999px;
	z-index: 2200;
	display: block;
	padding: 10px 15px;
	font-size: 14px;
	font-weight: bold;
	background: #fff;
	text-transform: uppercase;
	color: #111;
	opacity: 0;
	-webkit-transition: opacity 0.2s;
	-o-transition: opacity 0.2s;
	transition: opacity 0.2s;
}
#jb-skip-link:focus {
	left: 0;
	opacity: 1;
	text-decoration: underline;
	outline: -1px thin dotted !important;
	outline: 5px auto -webkit-focus-ring-color !important;
}