.homepage {
	.homepage__hero {
		background-image: url('../img/hero_femaleStudent_bw.jpg');
		background-color: $teal;
		background-size: cover;
		background-position: top;
		position: relative;
		&:before {
			content: '';
			background-image: url('../img/dot_background.png');
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			height: 100%;
			transform: rotate(180deg);
			opacity: 0.06;
			width: 50%;
			max-width: 384px;
			display: none;
			@include bp(sm) {
				display: block;
			}
		}
		&:after {
			content: '';
			background-image: url('../img/dot_background.png');
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			height: 100%;
			width: 50%;
			max-width: 384px;
			opacity: 0.06;
			display: none;
			@include bp(sm) {
				display: block;
			}
		}
		.homepage__hero__gradient {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#001387+0,0e817d+100 */
			background: rgb(0,19,135); /* Old browsers */
			background: -moz-linear-gradient(left, rgba(0,19,135,1) 0%, rgba(14,129,125,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(0,19,135,1) 0%,rgba(14,129,125,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(0,19,135,1) 0%,rgba(14,129,125,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001387', endColorstr='#0e817d',GradientType=1 ); /* IE6-9 */
			opacity: 0.75;
		}
		.homepage__hero__title {
			font-size: 36px;
			line-height: 44px;
			font-weight: 400;
			color: white;
			margin: 86px 0 24px;
			position: relative;
			z-index: 4;
			@include bp(sm) {
				margin: 106px 0 14px 20px;
			}
			@include bp(lg) {
				margin: 106px 24px 14px;
			}
		}
		.homepage__hero__body {
			font-size: 20px;
			line-height: 24px;
			color: white;
			font-weight: 400;
			margin-bottom: 22px;
			position: relative;
			z-index: 4;
			@include bp(sm) {
				margin-bottom: 26px;
				margin-left: 20px;
				padding-right: 106px;
			}
			@include bp(md) {
				padding-right: 150px;
				margin-left: 24px;
			}
		}
		.homepage__hero__link {
			position: relative;
			z-index: 4;
			text-align: left;
			margin-bottom: 94px;
			font-weight: 600;
			font-size: 14px;
			line-height: 17px;
		    padding: 14px 19px 13px 20px;
		    width: 172px;
		    transition: $all-t;
		    overflow: hidden;
		    .btn-text {
		    	position: relative;
		    }
		    .far {
		    	position: absolute;
	    	    top: -2px;
			    right: -30px;
			    font-size: 20px;
		    }
		    &:hover {
		    	width: 202px;
		    }
			@include bp(sm) {
				margin-bottom: 144px;
				margin-left: 20px;
			}
			@include bp(lg) {
				margin-left: 24px;
			}
		}
	}
	.studies__overlap {
		position: relative;
		&:after {
			@include bp(sm) {
				content: '';
				position: absolute;
				left: 0;
				bottom: 0;
				width: 41.6%;
				height: 30px;
				background: white;
			}
			@include bp(md) {
				width: calc(50% - 485px + 316px);
			}
			@include bp(lg) {
		    	width: calc(50% - 585px + 286px);
			}
		}
	}
	.homepage__studies {
		background: $background-light;
		position: relative;
		overflow: hidden;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 140px;
			background: white;	
			@include bp(sm) {
				top: -30px;
				height: 460px;
				width: 41.6%;
			}
			@include bp(md) {
				height: 460px;
				width: calc(50% - 485px + 316px);
			}
			@include bp(lg) {
			    width: calc(50% - 585px + 286px);
			}
		}
		.homepage__studies__title {
			font-size: 36px;
			line-height: 44px;
			color: $teal;
			margin: 28px 0 18px;
			text-align: center;
			font-weight: 400;
			@include bp(sm) {
				margin: 52px 0 14px;
				text-align: left;
				padding-left: 58px;
				padding-right: 60px;
			}
			@include bp(md) {
				padding-left: 40px;
				padding-right: 32px;
			}
			@include bp(lg) {
				padding-left: 20px;
				padding-right: 70px;
			}
		}
		.homepage__studies__body {
			display: none;
			@include bp(sm) {
				display: block;
				padding-left: 58px;
				padding-right: 56px;
				font-size: 16px;
				line-height: 24px;
				margin-bottom: 32px;
			}
			@include bp(md) {
				padding-left: 40px;
				padding-right: 32px;
			}
			@include bp(lg) {
				padding-left: 20px;
				padding-right: 70px;
			}
		}
		.homepage__studies__link {
			text-align: center;
			@include bp(sm) {
				text-align: left;
				padding-left: 58px;
				padding-right: 60px;
			}
			@include bp(md) {
				padding-left: 40px;
				padding-right: 32px;
			}
			@include bp(lg) {
				padding-left: 20px;
				padding-right: 94px;
			}
			&:after {
				content: '';
				display: block;
				background: white;
				height: 20px;
				margin-top: 6px;
			}
			.btn {
			    padding: 14px 25px 13px 26px;
			}
		}
		.homepage__studies__slideshow {
			width: 200%;
			@include bp-only(xs) {
				position: static;
			}
			&.slick-slider {
				@include bp(sm) {
					margin-bottom: 0;
				}
			}
			.homepage__studies__slideshow__card {
				display: block;
				min-height: 300px;
				width: 228px;
				background-size: 100% 100%;
				background-position: top;
				position: relative;
				margin: 25px 11px 76px;
				box-shadow: 0 0 4px 0 rgba(0,0,0,0.5);
				transition: $all-t;
				.btn {
					transition: $all-t;
				}
				&:hover {
					background-size: 110% 110%;
					cursor: pointer;
					.btn {
						background-color: $teal-dark;
					}
				}
				&:before {
					content: '';
					position: absolute;
					width: 100%;
					bottom: 0;
					left: 0;
					height: 128px;
					background-image: url('../img/dot_background-colorful2.png');
				}
				@include bp(sm) {
					margin: 40px 11px 150px;
				}
				.homepage__studies__slideshow__card__title {
					text-align: left;
					margin: 10px 0 0 10px;
					display: block;
					> span {
						padding: 7px 12px;
						color: $blue;
						background: white;
						font-size: 14px;
						line-height: 17px;
						font-weight: 400;
						display: inline-block;
					}
				}
				.homepage__studies__slideshow__card__info {
					display: block;
					text-transform: uppercase;
					position: absolute;
					bottom: 10px;
					left: 10px;
					.far {
						margin-left: 8px;
					}
				}
			}
			.slick-arrow {
				outline: none;
				z-index: 2;
				color: $blue;
				font-size: 18px;
				line-height: 18px;
				position: absolute;
				bottom: 15px;
				right: auto;
				left: calc(50% - 42px);
				@include bp(sm) {
					// left: 350px;
					left: 338px;
					bottom: 90px;
				}
				@include bp(md) {
					left: 550px;
					bottom: 88px;
				}
				@include bp(lg) {
					left: 788px;
				}
				&.slick-next {
					left: calc(50% + 18px);
					@include bp(sm) {
						// left: 404px;
						left: 381px;
					}
					@include bp(md) {
						left: 604px;
					}
					@include bp(lg) {
						left: 834px;
					}
					&:after {
						// content: '';
						// width: 3px;
						// height: 17px;
						// background: #ddd;
						// position: absolute;
					 //    left: -20px;
    		// 			top: 2px;
	    		    	content: '';
					    width: 3px;
					    height: 17px;
					    background: #ddd;
					    position: relative;
					    display: inline-block;
					    left: -20px;
					    top: 2px;
					    margin-left: -20px;
    					@include bp(sm) {
    						left: -17px;
    						margin-left: -14px;
    					}
    					@include bp(md) {
    						left: -22px;
    					}
    					@include bp(lg) {
    						left: -18px;
    						margin-left: -13px;
    					}
					}
				}
				&:hover, &:focus {
					cursor: pointer;
					color: $teal;
				}
			}
		}
	}
	.homepage__news-events {
		background: $blue;
		.homepage__news-events__slideshow {
			margin: 0 4px;
			@include bp(sm) {
				margin: 0 74px;
			}
			@include bp(md) {
				margin: 0 64px;
			}
			@include bp(lg) {
				margin: 0 122px;
			}
			.homepage__news-events__slideshow__slide {
				padding: 28px 22px 32px;
				margin: 52px 11px 68px;
				@include bp(sm) {
					margin: 60px 8px 10px;
					padding: 28px 22px 23px;
				}
				@include bp(md) {
					padding: 28px 22px 32px;
					margin: 60px 10px 10px;
				}
				@include bp(lg) {
					padding: 28px 40px 32px;
					margin: 60px 8px 10px;
				}
				&.slide--news {
					background: white;
					.homepage__news-events__slideshow__slide__date {
						position: relative;
						text-transform: uppercase;
						font-size: 14px;
						line-height: 22px;
						font-weight: 700;
						text-align: center;
						color: #4a4a4a;
						margin: 12px 0;
						&:after {
							content: '';
							position: absolute;
							width: 210px;
							height: 4px;
							background: #eee;
							left: calc(50% - 105px);
							bottom: 9px;
							z-index: 0;
							@include bp(sm) {
								width: 132px;
								left: calc(50% - 66px);
							}
							@include bp(md) {
								width: 210px;
								left: calc(50% - 105px);
							}
							@include bp(lg) {
								width: 280px;
								left: calc(50% - 140px);
							}
						}
						> span {
							background: white;
							padding: 0 14px;
							position: relative;
							z-index: 1;
						}
					}
				}
				&.slide--event {
					background: $gold-light;
					.homepage__news-events__slideshow__slide__tag {
						text-align: center;
						margin: -28px auto 0;
						color: white;
						background: $teal;
						font-size: 11px;
						line-height: 14px;
						text-transform: uppercase;
						padding: 13px;
						width: 118px;
						@include bp(md) {
							width: 186px;
						}
					}
					.homepage__news-events__slideshow__slide__day h4 {
						font-size: 20px;
						line-height: 40px;
						color: #222;
						font-weight: 500;
						margin: 12px 0 0;
						text-transform: uppercase;
						text-align: center;
					}
					.homepage__news-events__slideshow__slide__date {
						text-align: center;
						font-size: 36px;
						line-height: 40px;
						color: #222;
						font-weight: 700;
						margin: 0 0 12px;
						span {
							display: block;
							margin: 0 0 12px;
						}
						&:after {
							content: '';
							width: 222px;
							height: 4px;
							background: $gold;
							margin: 0 auto;
							display: block;
							@include bp-only(sm) {
								width: 132px;
							}
						}
					}
					.homepage__news-events__slideshow__slide__body p {
						margin: 8px 0 36px;
						font-size: 20px;
						line-height: 24px;
						color: #222;
					}
					.homepage__news-events__slideshow__slide__link a {
						font-size: 11px;
						text-transform: uppercase;
						font-weight: 700;
						color: $blue;
						.fa-chevron-right {
							margin-left: 8px;
							transition: $all-t;
						}
						&:hover {
							.fa-chevron-right {
								margin-left: 16px;
							}
						}
					}
				}
				.homepage__news-events__slideshow__slide__title h4{
					color: $blue;
					font-size: 20px;
					line-height: 24px;
				    font-weight: 400;
					text-align: center;
					margin: 0;
					transition: $all-t;
					&:hover {
						color: $teal;
					}
				}
				
				.homepage__news-events__slideshow__slide__body {
					> p {
						color: #4A4A4A;
						font-size: 14px;
						line-height: 22px;
						text-align: center;
						margin-bottom: 32px;
						@include bp-only(sm) {
							margin-bottom: 18px;
						}
					}
				}
				.homepage__news-events__slideshow__slide__link {
					text-align: center;
					.far {
						margin-left: 8px;
						transition: $all-t;
					}
				}
			}
			.slick-arrow {
				outline: none;
				z-index: 2;
				color: white;
				font-size: 18px;
				line-height: 18px;
				position: absolute;
				bottom: 9px;
				right: auto;
				left: calc(50% - 40px);
				@include bp(sm) {
					top: 186px;
					bottom: auto;
					height: 50px;
					width: 50px;
					background: $light-blue;
					left: -74px;
					transition: $all-t;
					&:hover, &:focus {
						background: $teal;
					}
				}
				@include bp(md) {
					left: -64px;
				}
				@include bp(lg) {
					left: -122px;
				}
				&.slick-next {
					left: calc(50% + 18px);
					@include bp(sm) {
						left: auto;
						right: -74px;
					}
					@include bp(md) {
						right: -64px;
					}
					@include bp(lg) {
						right: -122px;
					}
					&:after {
						content: '';
						width: 3px;
						height: 17px;
						background: $light-blue;
						position: absolute;
					    left: -19px;
    					top: 2px;
    					@include bp(sm) {
    						display: none;
    					}
					}
				}
				&:hover, &:focus {
					cursor: pointer;
					color: darken(white, 15%);
				}
			}
		}
		.homepage__news-events__link a {
			font-size: 14px;
			line-height: 17px;
			color: $blue;
			background: #eee;
			margin: 0 15px;
			padding: 22px 0;
			width: calc(100% - 30px);
			display: block;
			text-align: center;
			position: relative;
			top: 30px;
			z-index: 5;
			transition: $all-t;
			&:hover {
				background: #ddd;
			}
			@include bp(sm) {
				margin: 0 auto;
				max-width: 360px;
			}
		}
	}
	.homepage__info {
		position: relative;
		@include bp(sm) {
			overflow: hidden;
		}
		@include bp(md) {
			padding: 50px 0 0;
		}
		&:before {
			content: '';
			position: absolute;
			width: 100%;
			height: 46px;
			background-image: url('../img/dots_pattern_grey.png');
			top: 0;
			left: 0;
		}
		.col-sm-7 {
			position: relative;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100px;
				background-image: url('../img/info-body-gradient.png');
				z-index: -1;
				@include bp(sm) {
					display: none;
				}
			}
		}
	}
	.homepage__info__title {
		text-align: center;
		color: $teal;
		font-size: 24px;
		font-weight: 400;
		line-height: 30px;
		margin: 74px 0 40px;
		display: none;
		&.active {
			display: block;
		}
		@include bp(sm) {
			text-align: left;
			font-size: 36px;
			line-height: 44px;
			font-weight: 500;
			margin: 74px 0 8px;
		}
	}
	.homepage__info__side {
		@include bp(sm) {
			background: #eee;
			position: relative;
			padding: 37px 0 36px 28px;
			margin: 54px 0 60px;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 1000px;
				background: #eee;
				z-index: -1;
			}
		}
		@include bp(md) {
			padding: 39px 0 36px 18px;
		}
		@include bp(lg) {
			padding: 39px 0 36px 22px;
		}
		.homepage__info__side__entry {
			background: rgba(255, 255, 255, 0.0);
			-webkit-box-shadow: 0 0 0px 0px rgba(221, 221, 221, 0.0);
			box-shadow: 0 0 0px 0px rgba(221, 221, 221, 0.0);
			color: $blue;
			position: relative;
			transition: $all-t;
			.fa {
				color: #ddd;
				position: absolute;
				top: 20px;
				left: 11px;
				font-size: 10px;
				transition: $all-t;
			}
			&:hover, &:focus {
				outline: none;
				background: rgba(255, 255, 255, 1.0);
				-webkit-box-shadow: 0 0 4px 2px rgba(221, 221, 221, 1.0);
				box-shadow: 0 0 4px 2px rgba(221, 221, 221, 1.0);
				cursor: pointer;
				.homepage__info__side__entry__title {
					color: $teal;
				}
			}
			&.active {
				background: rgba(255, 255, 255, 1.0);
				-webkit-box-shadow: 0 0 4px 2px rgba(221, 221, 221, 1.0);
				box-shadow: 0 0 4px 2px rgba(221, 221, 221, 1.0);
				color: $teal;
				.fa {
					color: $gold-light;
				}
				&:hover {
					cursor: default;
				}
			}
			.homepage__info__side__entry__title {
				font-size: 16px;
				line-height: 24px;
				margin: 8px 0;
				padding: 13px 16px 13px 32px;
				font-weight: 500;
				@include bp(sm) {
					padding-bottom: 0;
					margin: 10px 0 3px;
				}
			}
			.homepage__info__side__entry__text {
				display: none;
				@include bp(sm) {
					display: block;
					padding: 0 23px 13px 32px;
					color: $text-color;
				}
			}
		}
	}
	.homepage__info__body {
		display: none;
		&.active {
			display: block;
		}
		.homepage__info__body__subtitle {
			font-size: 20px;
			line-height: 30px;
			color: #4A4A4A;
			margin: 14px 0 10px;
			font-weight: 500;
			display: inline-block;
			@include bp(sm) {
				line-height: 24px;
			}
		}
		.homepage__info__body__text {
			font-size: 16px;
			line-height: 24px;
			color: #4A4A4A;
			margin: 0 0 22px;
			font-weight: 500;
			@include bp(sm) {
				margin: 0 0 20px;
			}
		}
		.homepage__info__body__img-links {
			position: relative;
			.homepage__info__body__img-links__img {
				margin-bottom: 36px;
				position: relative;
				z-index: 1;
				&:before {
					content: '';
					height: 77%;
					width: 52%;
					background-image: url('../img/dots_bg_yellow.png');
					position: absolute;
					bottom: -20px;
					left: -15px;
					z-index: -1;
				}
				@include bp(sm) {
					max-width: 228px;
					float: left;
					margin-right: 20px;
				}
				@include bp(md) {
					max-width: 310px;
				}
			}
			.homepage__info__body__img-links__subtext {
				display: block;
				font-size: 14px;
				line-height: 17px;
				color: #4A4A4A;
				margin-bottom: 4px;
			}
			.homepage__info__body__img-links__links {
				padding: 10px 0 40px;
				.homepage__info__body__img-links__links__link {
					display: block;
					color: $blue;
					text-decoration: underline;
					font-weight: 700;
					font-size: 14px;
					line-height: 22px;
					margin-bottom: 6px;
					transition: $all-t;
					&:hover {
						color: $teal;
						text-decoration: none;
					}
					.far {
						color: $gold-light;
						margin-right: 10px;
					}
				}
			}
		}
	}
	.homepage__get-started {
		background: rgba(0, 19, 135, 0.75);
		position: relative;
		padding-bottom: 44px;
		@include bp(sm) {
			padding: 0;
		}
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			height: 100%;
			width: 100%;
			background-image: url('../img/getStarted_bg.jpg');
			background-size: cover;
			background-position: top;
		}
		.homepage__get-started__left {
			@include bp(md) {
				padding-left: 40px;
			}
			.homepage__get-started__left__title {
				font-size: 36px;
				line-height: 44px;
				color: $gold-light;
				font-weight: 400;
				margin: 60px 0 4px;
				text-align: center;
				@include bp(sm) {
					text-align: left;
					margin: 48px 0 4px;
				}
				@include bp(md) {
					margin: 58px 0 4px;
				}
			}

			.homepage__get-started__left__subtitle {
				margin: 0 0 42px;
				color: white;
				font-size: 20px;
				line-height: 24px;
				text-align: center;
				font-weight: 400;
				@include bp(sm) {
					text-align: left;
					margin: 0 0 54px;
				}
			}
		}

		.homepage__get-started__right {
			@include bp(sm) {
				text-align: right;
			}
			@include bp(md) {
				text-align: left;
				width: calc(100% + 12px);
			}
			.homepage__get-started__right__btn {
				text-align: center;
				margin: 0 0 20px;
				> a {	
					font-size: 16px;
					line-height: 24px;
					.far {
						margin-left: 24px;
					}
				}
				@include bp(sm) {
					display: inline-block;
					margin: 78px 17px 72px 0;
					> a {
						padding: 13px 18px;
					}
				}
			}
		}
	}

	.homepage__spotlight {
		padding: 54px 0 62px;
		@include bp(sm) {
			padding: 92px 0 52px;
			position: relative;
			&:before {
				content: '';
				position: absolute;
				height: 100%;
				width: 50%;
				max-width: 384px;
				top: 0;
				left: 0;
				background-image: url(../img/dot_background-left.png);
				background-size: auto;
				z-index: -1;
			}
			&:after {
				content: '';
				position: absolute;
				height: 100%;
				width: 50%;
				max-width: 384px;
				top: 0;
				right: 0;
				background-image: url(../img/dot_background.png);
				background-size: auto;
				background-position: right top;
				z-index: -2;
			}
		}
		@include bp(md) {
			padding: 72px 0 58px;
		}
		@include bp(lg) {
			padding: 72px 0 62px;
		}

		.homepage__spotlight__title {
			position: relative;
			@include bp-only(xs) {
			    left: calc(50% - 141px);
			}
			@include bp(md) {
				margin-left: 62px;
			}
			@include bp(lg) {
				margin-left: 60px;
			}
			.homepage__spotlight__bg {
				position: absolute;
				z-index: 0;
				top: -12px;
				left: 0;
				background: $gold-light;
				height: 307px;
				width: 244px;
				@include bp(sm) {
					height: 300px;
					width: 238px;
				}
				@include bp(md) {
					height: 340px;
					width: 270px;
				}
				@include bp(lg) {
					top: -14px;
				}
			}
			.homepage__spotlight__title__text {
				font-size: 16px;
				line-height: 19px;
				text-transform: uppercase;
				font-weight: 500;
				color: $blue;
				letter-spacing: 3px;
				margin: 14px 14px 14px 28px;
				position: relative;
				z-index: 1;
			}
		}
		.homepage__spotlight__img {
			margin-top: 9px;
			padding-left: 28px;
			position: relative;
			@include bp-only(xs) {
			    left: calc(50% - 141px);
			}
			@include bp(md) {
				margin-left: 62px;
			}
			@include bp(lg) {
				margin-left: 60px;
				margin-top: 12px;
			}
			&:before {
				content: '';
				width: 262px;
				height: 325px;
				position: absolute;
				z-index: -1;
				top: -9px;
				left: 19px;
				background: #eee;
				@include bp(sm) {
					width: 256px;
					height: 317px;
					left: 18px;
				}
				@include bp(md) {
					width: 290px;
					height: 360px;
					left: 20px;
					top: -10px;
				}
			}
			img {
				-webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
				box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
				height: 307px;
				width: 244px;
				@include bp(sm) {
					height: 300px;
					width: 238px;
				}
				@include bp(md) {
					height: 340px;
					width: 270px;
				}
			}
		}

		.homepage__spotlight__name {
			display: block;
			margin: 38px 0 26px;
			position: relative;
			z-index: 2;
			@include bp(sm) {
				margin: 34px 0 26px;
			}
			@include bp(md) {
				padding: 0 40px;
			}
			@include bp(lg) {
				padding: 0 52px;
			}
			h2 {
				margin: 0;
				font-size: 24px;
				line-height: 30px;
				color: $blue;
				font-weight: 500;
				display: inline-block;
				background: white;
				padding-right: 20px;
			}
			&:before {
				content: '';
				display: block;
				background: #eee;
				height: 4px;
				width: 100%;
				position: absolute;
				top: calc(50% - 2px);
				z-index: -1;
				@include bp(md) {
					left: 40px;
					width: calc(100% - 80px);
				}
				@include bp(lg) {
					left: 52px;
					width: calc(100% - 104px);
				}
			}
		}
		.homepage__spotlight__subtitle {
			text-transform: uppercase;
			font-size: 12px;
			line-height: 14px;
			@include bp(md) {
				padding: 0 40px;
			}
			@include bp(lg) {
				padding: 0 52px;
			}
			p {
				margin: 0 0 6px;
			}
		}
		.homepage__spotlight__body {
			font-size: 20px;
			line-height: 30px;
			margin-top: 20px;
			position: relative;
			@include bp(md) {
				padding: 0 40px;
			}
			@include bp(lg) {
				padding: 0 52px;
			}
			&:after {
				content: '';
				position: absolute;
				z-index: -1;
				background: url('../img/quotation-mark.png');
				top: -5px;
				left: -10px;
				height: 50px;
				width: 68px;
				@include bp(md) {
					left: 30px;
				}
				@include bp(lg) {
					left: 42px;
				}
			}
			p {
				margin-bottom: 18px;
			}
		}
		.homepage__spotlight__link {
			@include bp(md) {
				padding: 0 40px;
			}
			@include bp(lg) {
				padding: 0 52px;
			}
			.btn {
				.far {
					margin-left: 8px;
				}
			}
		}
	}

	.slick-arrow {
		background: transparent;
		border: 0;
	}
}