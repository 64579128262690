@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "./fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 1 !default;

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots, .rte .slick-dots {
    position: absolute;
    bottom: 18px;
    left: 0;
    list-style: none;
    text-align: right;
    padding: 0;
    margin: 0;
    width: 190px;
    transition: 0.3s all ease;
    display: none !important;
    @include bp(sm) {
        display: table !important;
        width: 330px;
        bottom: 92px;
    }
    @include bp(md) {
        width: 520px;
        bottom: 92px;
    }
    @include bp(lg) {
        width: 750px;
    }
    li {
        position: relative;
        display: table-cell;
        height: 6px;
        width: auto;
        margin: 0;
        padding: 0;
        cursor: pointer;
        transition: 0.3s all ease;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 6px;
            width: 100%;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: $blue;
            padding: 5px;
            cursor: pointer;
            transition: 0.3s all ease;
            &:hover, &:focus {
                outline: none;
                &:before {
                    background: $blue;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 100%;
                height: 6px;
                font-family: $slick-font-family;
                font-size: $slick-dot-size;
                line-height: 20px;
                text-align: center;
                background: white;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                transition: 0.3s all ease;
            }
        }
        &.slick-active button:before {
            background: $blue;
        }
    }
}
