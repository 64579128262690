footer {
	background: #222;
	color: white;
	position: relative;
	border-top: 4px solid #ddd;
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		background-image: url('../img/dots_pattern_footer.png');
		background-position: bottom;
		width: 100%;
		height: 35px;
	}
	a {
		color: white;
		font-weight: 400;
		&:hover, &:focus {
			color: $gold-light;
		}
	}
	.back-to-top {
		position: absolute;
		left: calc(50% - 22.5px);
		top: -22.5px;
		z-index: 5;
		color: #4a4a4a;
		.btt {
			display: block;
			height: 45px;
			width: 45px;
			background: white;
			border-radius: 50px;
			transition: $all-t;
			&:hover, &:focus {
				background: darken(white, 15%);
			}
		}
		.far {
			color: #4a4a4a;
			font-size: 21px;
			left: calc(50% - 6px);
		    top: calc(50% - 10px);
		    position: relative;
		}
		&:focus {
			outline: none;
			.btt {
				background: darken(white, 15%);
			}
		}
	}
	.footer__address {
		margin: 82px 0 66px;
		@include bp(sm) {
			margin: 130px 0 64px 36px;
		}
		@include bp(lg) {
			margin: 130px 0 64px 20px;
		}
		.footer__address__logo {
			float: left;
			margin-right: 24px;
		}
		.footer__address__text {
			span {
				display: block;
				font-size: 16px;
				line-height: 24px;
				font-weight: 400;
				a {
					color: $gold;
					&:hover {
						color: $gold-dark;
					}
				}
			}
			@include bp(md) {
				span {
					&:nth-child(1) {
						display: inline-block;
					}
					&:nth-child(2) {
						display: inline-block;
						&:before {
							content: '';
							display: inline-block;
							height: 18px;
							width: 1px;
							background: #ddd;
							position: relative;
							top: 4px;
							right: 6px;
						}
						margin-left: 10px;
					}
				}
			}
		}
	}
	.footer__links, .footer__social {
		margin-bottom: 42px;
		@include bp(sm) {
			margin: 114px 0 0 -8px;
		}
		@include bp(md) {
			margin: 114px 0 0 14px;
		}
		@include bp(lg) {
			margin: 114px 0 0 14px;
		}
		.footer__heading {
			font-size: 20px;
			line-height: 30px;
		}
		.footer__links__list {
			position: relative;
			@include bp(sm) {
				top: 8px;
			}
			a {
				display: block;
				font-size: 12px;
				line-height: 18px;
				margin: 6px 0 5px;
				.far {
					margin: 0 16px 0 20px;
					font-size: 14px;
				}
				.fa-twitter {
					margin: 0 13px 0 16px;
				}
				.fa-instagram {
					margin: 0 14px 0 16px;
				}
				.fa-envelope {
					margin: 0 13px 0 16px;
				}
			}
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 3px;
				background: #4a4a4a;
			}
		}
	}
	.footer__links {
		.footer__links__list {
			a {
				margin-left: 16px;
			}
		}
	}
	.footer__copyright {
		font-size: 12px;
		line-height: 15px;
		color: #9b9b9b;
		text-align: center;
		margin-bottom: 6px;
		@include bp(sm) {
			text-align: left;
			display: table;
			margin-top: -12px;
			margin-left: 36px;
		}
		@include bp(md) {
			display: inline-block;
		}
		@include bp(lg) {
			margin-left: 20px;
		}
	}
	.attribution {
		font-size: 12px;
		line-height: 15px;
		color: #9b9b9b;
		text-align: center;
		display: block;
		margin-bottom: 112px;
		@include bp(sm) {
			text-align: left;
			display: table;
			margin-left: 36px;

			.attribution__by {
				margin: 0 2px;
			}
		}
		@include bp(md) {
			display: inline-block;
			margin-left: 10px;
			&:before {
				content: '';
				display: inline-block;
				height: 12px;
				width: 1px;
				background: #9b9b9b;
				position: relative;
				right: 5px;
				top: 2px;
			}
			.attribution__by {
				margin: 0;
			}
		}
	}
}