// SmartMenus styles
// Begin are core styles (adapted from v1.0.0) - do not edit these:
.sm { 
	position: relative;
	z-index: 9999;
}
.sm, .sm ul, .sm li {
	display: block;
	list-style: none;
	margin: 0;
	padding: 0;
	line-height: normal;
	direction: ltr;
	text-align: left;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}
.sm ul { 
	display: none; 
}
.sm li, .sm a { 
	position: relative;
}
.sm a {
	display: block;
}
.sm:after { 
	content: "\00a0";
	display: block;
	height: 0;
	font: 0px/0 serif;
	clear: both;
	visibility: hidden;
	overflow: hidden;
}
// (end core SmartMenus styles)

// SmartMenus theme, based on the JCSD navigation. This will need to be modified heavily to
// fit the project, but it serves as a good starting point.
.sm {
	margin: 0 (-$base-padding);
}
.sm a {
	padding: 15px 58px 16px 20px;
	color: #222;
	transition: $all-t;
}
.sm a:hover, .sm a:focus, .sm a:active {
	color: $blue;
}
.sm .main-menu__item-link .sub-arrow {
	position: absolute;
	top: 50%;
	margin-top: -17px;
	left: auto;
	right: 4px;
	width: 34px;
	height: 34px;
	overflow: hidden;
	font-family: monospace; 
	font-weight: bold;
	line-height: 34px;
	text-align: center;
	text-shadow: none;
	transition: $all-t;
	&:before {
		color: $teal;
		content: '';
		font-family: "Font Awesome 5 Pro";
		font-weight: 400;
		transition: $all-t;
	}
}
.sm a.highlighted span.sub-arrow:before {
	color: white;
	display: block;
	transform: rotate(180deg);
}
.sm a.highlighted {
	background: $blue;
}
.sm li.main-menu__item {
	> a {
		font-weight: 400;
		font-size: 16px;
		color: $blue;
		&.highlighted {
			color: white;
		}
		@include bp(md) {
			color: #222;
		}
	}
}
.sm .top-menu {
	background: $teal;
	color: white;
	position: relative;
	.main-menu__item {
		border-top: 1px solid $teal-light;
		border-bottom: 0;
		.main-menu__item-link {
			color: white;
			font-weight: 300;
			transition: $all-t;
			&:hover {
				color: darken(white, 20%);
			}
		}
		&:last-child {
			border-bottom: 1px solid $teal-light;
		}
	}
	&:after {
	    position: absolute;
	    content: '';
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 1200px;
	    z-index: -1;
	    background: #0e817d;
	}
}
.sm li {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.sm .sub-menu {
	background: #eee;
	padding: 7px 0;
	a {
		padding: 12px 16px 12px 17px;
		background: #eee;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: 0.23px;
		font-weight: 400;
	}
	li {
		border: 0;
	}
}

@include bp(md) {
	.sm {
	}
	.sm ul {
		position: absolute;
		width: 12em;
	}
	.sm li {
		float: left;
	}
	.sm .sub-menu li {
		float: none;
	    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	}
	.sm .sub-menu a {
		white-space: normal;
	}
	.sm .sm-nowrap > li > a, .sm .sm-nowrap > li > :not(ul) a {
		white-space: nowrap;
	}
	.sm a {
		padding: 0;
		white-space: nowrap;
		color: $primary-color;
	}
	.sm a:hover, 
	.sm a:focus, 
	.sm a:active, 
	.sm a.highlighted {
		color: $blue !important;
		background: transparent;
	}
	.sm .sub-menu a {
		color: $text-color;
	}
	.sm .sub-menu a:hover, 
	.sm .sub-menu a:focus, 
	.sm .sub-menu a:active,
	.sm .sub-menu a.highlighted {
		color: white !important;
		background: $blue !important;
	}
	.sm a.highlighted span.sub-arrow:before {
		display: none;
	}
	.sm li {
		border-top: 0;
	}
	.sm .sub-menu {
		border-top: 4px solid $gold;
		background: #ffffff;
		min-width: 100%;
		font-weight: 600;
		padding: 0;
		z-index: 30;
		margin-right: 25px;
	}
	.sm .sub-menu a {
		border: 0 !important;
		color: #000;
		font-weight: 400;
		font-size: 12px;
		line-height: 20px;
		&:hover,
		&:active,
		&:focus {
			color: white;
		}
	}
	.sm .main-menu__item-link {
		z-index: 20;
	}
	.sm .main-menu__item-link .sub-arrow {
		position: static;
		margin: 0;
		display: inline;
		background: transparent;
		height: auto;
		line-height: inherit;
		@include bp(md) {
			display: none;
		}
	}
	.sm span.scroll-up,
	.sm span.scroll-down {
		position: absolute;
		display: none;
		visibility: hidden;
		overflow: hidden;
		background: white;
		height: 20px;
	}
	.sm span.scroll-up-arrow, .sm span.scroll-down-arrow {
		position: absolute;
		top: -2px;
		left: 50%;
		margin-left: -8px;
		width: 0;
		height: 0;
		overflow: hidden;
		border-width: 8px;
		border-style: dashed dashed solid dashed;
		border-color: transparent transparent #555555 transparent;
	}
	.sm span.scroll-down-arrow {
		top: 6px;
		border-style: solid dashed dashed dashed;
		border-color: #555555 transparent transparent transparent;
	}
	.sm .sub-menu .page_item { 
		&:last-child {
			a {
				border: 0 !important;
			}
		}
		a {
			border-bottom: 1px solid #eee !important;
			padding: 10px 16px;
		}
	}
}
@include bp(md) {
	.sm .sub-menu {
		margin-left: 14px;
	}
}


.sm.main-menu__items {
	@include bp(md) {
		margin: 0 auto;
		// display: table;
		float: none;
		display: -ms-flexbox;
	    display: flex;
	    -ms-flex-align: start;
	    align-items: flex-start;
	    -ms-flex-pack: end;
	    justify-content: flex-end;
	}
}
.sm .main-menu__item {
	@include bp(md) {
		// display: table-cell;
		// width: 1%;
		float: none;
		text-align: center;
		position: relative;
		display: block;
		&:first-child {
			border-left-color: transparent;
		}
		&:first-child:before {
			display: none;
		}
	}
}
.sm li.main-menu__item .main-menu__item-link {
	@include bp(md) {
		font-weight: 500;
		display: block;
		font-size: 20px;
		line-height: 24px;
		white-space: nowrap;
		padding: 38px 14px;
		color: #222;
		.sub-arrow {
			font-family: Arial, Helvetica, sans-serif;
			color: #b4b4b4;
			margin-left: 8px;
			font-size: 15px;
			font-weight: normal;
		}
	}
}

.main-menu__btn {
	position: absolute;
	right: 10px;
	top: -62px;
	display: block;
	padding: 2px 10px;
	white-space: nowrap;
	cursor: pointer;
	line-height: 2;
	font-size: 12px;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	@include bp(md) {
		position: absolute;
		top: -99999px;
	}
	@include user-select(none);
	&:hover,
	&:focus {
		outline: none;
		.main-menu__icon {
			background: $blue;
			&:before {
				color: white;
			}
		}
	}
}
.main-menu__btn-text {
	color: $text-color;
	line-height: 1;
	vertical-align: -1px;
	display: none;
	@include bp(sm) {
		display: inline-block;
		font-size: 20px;
		line-height: 24px;
	}
}
.main-menu__icon:before {
	@include fa('\f0c9');
	font-size: 19px;
	color: $blue;
	display: inline-block;
	vertical-align: -3px;
	transition: $all-t;
}
.main-menu__btn--open {
	display: block;
	padding: 16px 19px;
	white-space: nowrap;
	cursor: pointer;
	line-height: 2;
	font-size: 12px;
	text-align: right;
	border-top: 4px solid $gold;
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
	@include bp(md) {
		display: none;
	}
	.main-menu__icon--open {
		transition: $all-t;
		background: #eee;
		border-radius: 50px;
	    padding: 15px 16px 14px 17px;
		&:before {
			font-size: 16px;
			color: $blue;
			display: inline-block;
			vertical-align: -3px;
			transition: $all-t;
		}
		&:hover, &:focus {
			background: $blue;
			&:before {
				color: white;
			}
		}
	}
	&:focus {
		outline: none;
		.main-menu__icon--open {
			background: $blue;
			&:before {
				color: white;
			}
		}
	}
}
.main-menu__icon {
	background: white;
	border-radius: 50px;
	padding: 15px 14px 15px 15px;
	transition: $all-t;
	@include bp(sm) {
		margin: 0 0 0 18px;
	}
}
.main-menu__state {
	position: absolute;
	top: -99999px;
}
.main-menu__state:checked ~ .main-menu__btn {
	.main-menu__icon {
		background: $blue;
		&:before {
			color: white;
		}
	}
}
.main-menu__state:not(:checked) ~ .main-menu__items {
	display: none;
	@include bp(md) {
		display: flex;
	}
}
.main-menu__state:checked ~ .main-menu__items {
	display: block;
	@include bp(md) {
		display: flex;
	}
}
