/**
 * Miscellaneous tweaks to go at the end of the stylesheet.
 */

/* Social media icons */
ul.social {
	list-style: none;
	margin-left: $base-padding / 2;
	margin-top: ($base-padding + 5);
	text-align: right;
	li {
		display: inline-block;
		font-size: 1.5em;
		margin-left: $base-padding / 2;
		a {
			display: block;
			color: #E2E2E2;
			@include transition(color $transition-duration);
			.fa-inverse {
				color: #000000 !important;
			}
			&:hover,
			&:focus,
			&:active {
				color: darken(#E2E2E2, 5%);
			}
		}
		.social__title {
			text-indent: -9999px;
			text-align: left;
			display: block;
		}
	}
}


/* Tiled Gallery tweaks */
#jp-carousel-comment-form-container,
#jp-carousel-comments-loading,
#jp-carousel-comment-form,
.jp-carousel-commentlink,
.jp-carousel-image-exif,
.jp-carousel-buttons { 
	display: none !important;
	margin: 0; padding: 0;
	border: none;
	opacity: 0;
}
.rte .tiled-gallery { margin: 20px 0; }


/* Fixes an issue with when body is made "position: relative" by Google Translate */
@media (max-width: 600px) {
	html #wpadminbar { top: -46px; }
}


/* Hide Google Translate logo with no alt text */
.goog-gt-tt .logo img { display: none; }