// Interior sidebar navigation styles
.sidenav {
	padding: 5px $base-padding 10px;
	a {
		font-weight: 600;
		display: block;
		padding: 3px 0;
		&:hover,
		&:focus,
		&:active {
			color: darken($primary-color, 30%);
		}
	}
	> li {
		padding: 0 0 0 5px;
		.children {
			font-size: 13px;
			line-height: 1.25;
			background: #eee;
			padding-left: 0;
			.page_item {
				border: 0;
				a {
					padding: 9px 30px;
					font-weight: normal;
					color: #222;
					text-transform: none;
					font-size: 14px;
					line-height: 17px;
					&:hover,
					&:focus,
					&.active {
						color: $blue;
					}
				}
				&:hover, &:focus {
					background: transparent;
				}
			}
			.current_page_item > a {
				color: $primary-color;
			}
		}
		.children .children {
			display: none;
		}
		&.current_page_item > .children,
		&.current_page_parent > .children,
		&.current_page_ancestor > .children,
		.children .current_page_item .children,
		.children .current_page_parent .children,
		.children .current_page_ancestor .children {
			display: block;
		}
	}
}
.news .sidenav__wrap {
	@include bp(md) {
		margin-top: 82px;
	}
	@include bp(lg) {
		margin-top: 94px;
	}
}
.sidenav__wrap {
	background: white;
	margin: 42px 0 32px;
	cursor: pointer;
	border: 1px solid #ddd;
	@include bp(sm) {
		margin: 42px 0 40px;
	}
	@include bp(md) {
		margin: 94px 0 30px;
		background: transparent;
		cursor: auto;
	}
	a, a:hover {
		text-decoration: none;
	}
	.sidenav__title {
		display: block;
		padding: 15px 30px;
		background: white;
		color: $blue;
		font-weight: 500;
		letter-spacing: 0.2px;
		text-transform: uppercase;
		position: relative;
		font-size: 12px;
		line-height:20px;
		transition: $all-t;
		@include bp(md) {
			display: none;
		}
		&:hover, &:focus {
			background: #eee;
			outline: none;
		}
		.ico-opener {
			position: absolute;
			top: 16px;
			right: 22px;
			.far {
				color: $blue;
				font-size: 18px;
				line-height: 18px;
				transition: 0.3s all ease;
				display: block;
				height: 100%;
			}
		}
	}
	&.sidenav--active {
		.far {
			-webkit-transform: rotate(180deg);     /* Chrome and other webkit browsers */
			-moz-transform: rotate(180deg);        /* FF */
			-o-transform: rotate(180deg);          /* Opera */
			-ms-transform: rotate(180deg);         /* IE9 */
			transform: rotate(180deg);             /* W3C compliant browsers */

			/* IE8 and below */
			filter: progid:DXImageTransform.Microsoft.Matrix(M11=-1, M12=0, M21=0, M22=-1, DX=0, DY=0, SizingMethod='auto expand');
		}
	}
	.sidenav {
		padding: 0;
		margin: 0;
		li {
			&:before {
				display: none;
			}
		}
		.page_item {
			list-style-type: none;
			padding: 0;
			border-bottom: 1px solid #ddd;
			transition: $all-t;
			display: block;
			&:last-child {
				border: 0;
			}
			a {
				padding: 16px 30px;
				text-transform: uppercase;
				font-weight: 500;
				letter-spacing: 0.2px;
				font-size: 12px;
				line-height: 20px;
				transition: $all-t;
				outline: none;
				&:focus {
					background: darken(white, 8%);
				}
			}
			&.active {
				background: $blue;
				> a {
					color: white;
					&:focus {
						background: darken($blue, 10%);
					}
				}
			}
			&:hover, &:focus {
				background: darken(white, 8%);
				&.active {
					background: darken($blue, 10%);
				}
			}
		}
	}
}
