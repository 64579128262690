// Bootstrap-ish styles for Gravity Forms. 
// Make sure "Output CSS" is set to "No" in Gravity Forms settings.
.rte .gform_wrapper ul {
	padding-left: 0;
	margin-left: 0;
	list-style: none;
}
.rte .gform_wrapper li { 
	margin-bottom: 10px;
	display: block;
	@include bp(sm) {
		margin-bottom: 15px;
	}
}
.gform_wrapper form { margin-bottom: 0; }
.gform_wrapper .gfield_label { 
	font-weight: 400; 
	display: block;
	margin-bottom: 5px;
}
.gform_wrapper .gfield_required {
	padding-left: 1px;
	color: #b94a48;
}
.gfield .ginput_container_name {
	.name_last > input {
		@include bp-only(xs) {
			margin-top: 10px;
		}
	}
}
.gform_wrapper {
	margin: 22px 0;
}
.gfield input[type="text"],
.gfield input[type="url"],
.gfield input[type="email"],
.gfield input[type="tel"],
.gfield input[type="number"],
.gfield input[type="password"],
.gfield select,
.gfield textarea {
	display: block;
	width: 100%;
	height: 40px;
	padding: 11px 15px;
	margin-bottom: 3px;
	font-size: $font-size-base;
	line-height: $line-height-base;
	color: $text-color;
	vertical-align: middle;
	background-color: #ffffff;
	background-image: none;
	border: 1px solid #ddd;
	border-radius: 2px;
	-webkit-transition: border-color ease-in-out $transition-duration;
	transition: border-color ease-in-out $transition-duration;

	&::-moz-placeholder {
		font-size: 14px;
		line-height: 17px;
		font-weight: 400;
		color: #4a4a4a;
		opacity: 1;
	}
	&:-ms-input-placeholder {
		font-size: 14px;
		line-height: 17px;
		font-weight: 400;
		color: #4a4a4a;
		opacity: 1;
	}
	&::-webkit-input-placeholder { 
		font-size: 14px;
		line-height: 17px;
		font-weight: 400;
		color: #4a4a4a;
		opacity: 1;
	}
}
.ginput_container_time {
	width: 15.5%;
	min-width: 85px;
	display: inline-block;
	height: 70px;
	margin-bottom: -36px;
	&.gfield_time_hour {
		position: relative;
		padding-right: 12px;
		&:after {
			content: ':';
			font-size: 16px;
			position: absolute;
			top: 6px;
    		right: -2px;
		}
	}
	&.gfield_time_minute {
		padding-left: 8px;
		padding-right: 10px;
	}
	&.gfield_time_ampm {
		padding-left: 10px;
		position: relative;
		bottom: 36px;
	}
}

.rte .gform_wrapper .gfield--name {
	@include bp-only(xs) {
		margin-bottom: 4px;
	}
}
.rte .gfield_radio {
	margin-bottom: 4px;
}
.rte .gfield_checkbox > li, .rte .gfield_radio > li {
	margin-bottom: -4px;
	label {
		position: relative;
		top: 2px;
	}
}
.gfield select {
	background: #eee;
	margin-bottom: -12px;
	-moz-appearance: none; /* Firefox */
    -webkit-appearance: none; /* Safari and Chrome */
    appearance: none;
    position: relative;
    padding: 11px 15px 8px;
    &:before {
    	content: '\f0dc';
    	font-family: 'Font Awesome 5 Pro';
    	font-size: 16px;
    	position: absolute;
    	top: 12px;
    	right: 16px;
    }
}
.gfield input[type="checkbox"], .gfield input[type="radio"] { 
	margin-right: 8px; 
	margin-left: 10px;
	position: relative;
	top: 1px;
}
.gfield input:focus,
.gfield select:focus,
.gfield textarea:focus {
	border-color: lighten($primary-color, 23%);
	outline: 0;
}
.gfield textarea { height: auto; }
.gfield .textarea.small { height: 80px; width: 100%; }
.gfield .textarea.medium { height: 180px; width: 100%; }
.gform_button {
	display: inline-block;
	margin-bottom: 0;
	font-weight: normal;
	text-align: center;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	background-image: none;
	border: none;
	border-bottom: 4px solid transparent;
	white-space: nowrap;
	@include button-size($btn-padding, $font-size-base, $line-height-base, $btn-border-radius-base);
	@include user-select(none);
	@include transition(all $transition-duration);

	&,
	&:active,
	&.active {
		&:focus,
		&.focus {
			@include tab-focus;
		}
	}

	&:hover,
	&:focus,
	&.focus {
		color: $text-color;
		text-decoration: none;
	}

	&:active,
	&.active {
		outline: 0;
		background-image: none;
		box-shadow: inset 0 3px 5px rgba(0,0,0,0.125);
	}

	&.disabled,
	&[disabled],
	fieldset[disabled] & {
		cursor: not-allowed;
		opacity: 0.65;
		box-shadow: none;
	}
	@include button-variant(#FFFFFF, #7B66AD, #5a4099);
}
.gform_button.disabled,
.gform_button[disabled],
fieldset[disabled] .gform_button {
	cursor: not-allowed;
	pointer-events: none;
	opacity: 0.65;
	-webkit-box-shadow: none;
	box-shadow: none;
}
.gform_wrapper .gfield_error .gfield_label,
.gform_wrapper .gfield_error .validation_message { color: #b94a48; }
.gform_wrapper .gfield_error input,
.gform_wrapper .gfield_error select,
.gform_wrapper .gfield_error textarea {
	border-color: #b94a48;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.gform_wrapper .gfield_error input:focus,
.gform_wrapper .gfield_error select:focus,
.gform_wrapper .gfield_error textarea:focus {
	border-color: #953b39;
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #E53B1F;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #E53B1F;
}
.validation_error {
	padding: $base-padding;
	margin: 0 0 $base-padding 0;
	border: 1px solid transparent;
	border-radius: 0;
	color: #FFFFFF;
	border-color: darken(#E53B1F, 5%);
	background-color: #E53B1F;
}
.gform_confirmation_wrapper {
	padding: $base-padding;
	margin: ($base-padding * 2) 0 $base-padding 0;
	border: 1px solid transparent;
	border-radius: 0;
	background-color: #dff0d8;
	border-color: #d6e9c6;
	color: #468847;
	font-weight: 600;
}
.gform_heading { margin-bottom: $base-padding; }
.gfield {
	&:before,
	&:after {
		content: " ";
		display: table;
	}
	&:after {
		clear: both;
	}
}
.gform_wrapper .ginput_complex label {
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
    position: relative;
    bottom: 6px;
}
.gform_wrapper .ginput_complex .ginput_full { 
	display: block; 
	margin-bottom: $base-padding;
}
.gform_wrapper li {
	&:before {
		display: none;
	}
	list-style-type: none;
}
.ginput_container_date {
	position: relative;
	&:after {
		content: '\f073';
		position: absolute;
		top: 8px;
		right: 18px;
		font-family: "Font Awesome 5 Pro";
		font-weight: 400;
		font-size: 14px;
		@include bp(sm) {
			right: calc(50% + 30px);
		}
	}
	.datepicker {
		&::-moz-placeholder {
			font-size: 14px;
			line-height: 17px;
			font-weight: 400;
			color: #4a4a4a;
			opacity: 1;
		}
		&:-ms-input-placeholder {
			font-size: 14px;
			line-height: 17px;
			font-weight: 400;
			color: #4a4a4a;
			opacity: 1;
		}
		&::-webkit-input-placeholder { 
			font-size: 14px;
			line-height: 17px;
			font-weight: 400;
			color: #4a4a4a;
			opacity: 1;
		}
	}
}
.ginput_container_select, .gfield_time_ampm {
	position: relative;
	&:before {
	    content: '\f0dc';
	    font-family: 'Font Awesome 5 Pro';
	    font-weight: 600;
	    font-size: 16px;
	    position: absolute;
	    top: 8px;
	    left: calc(100% - 26px);
	    z-index: 1;
	    @include bp(sm) {
	    	left: calc(47.5% - 26px);
	    }
	}
}
.gfield_time_ampm {
	&:before {
		left: auto;
		right: 10px;
	}
}
@include bp(sm) {
	.gform_wrapper .ginput_complex .ginput_left,
	.gform_wrapper .ginput_complex .ginput_right,
	.gform_wrapper .ginput_complex.gf_name_has_2 .name_first,
	.gform_wrapper .ginput_complex.gf_name_has_2 .name_last {
		float: left;
		display: block;
		width: 47.5%;
	}
	.ginput_container input.small,
	.ginput_container select.small {
		width: 25%;
	}
	.ginput_container input.medium,
	.ginput_container select.medium {
		width: 47.5%;
	}
	.gform_wrapper .ginput_left,
	.gform_wrapper .ginput_complex.gf_name_has_2 .name_first { 
		margin-right: 5%; 
		clear: both; 
	}
	.rte .gform_wrapper ul { display: block; }
	.gform_wrapper .gform_body { width: 100%; }
	.col-1 .gform_wrapper .gform_body { width: 100%; }
	.gfield .simple_captcha_medium { width: 47.5%; }
}
.gform_wrapper .gform_ajax_spinner { margin-left: 10px; }
.gf_clear { clear: both; width: 100%; height: 1px; margin: 0 0 -1px; }
td.gfield_list_icons { min-width: 50px; }
.gfield_list th { font-size: 13px; }
.ginput_recaptcha,
.gfield_captcha {
	margin-bottom: $base-padding;
}
.ginput_counter {
	float: right;
	font-style: italic;
}
.gfield_description {
	font-style: italic;
}
.ginput_container_fileupload + .gfield_description {
	margin-top: $base-padding / 2;
}