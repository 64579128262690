/** Responsive Tables (https://github.com/zurb/responsive-tables)
 *  This is a Sass adaptation of Responsive Tables from Foundation v2.1.4 (http://foundation.zurb.com).
 *  Complementary to responsive-tables.js.
 */

@include bp('max-width: 767px') {
	.pinned {
		position: absolute;
		left: 0; 
		top: 0; 
		background: #fff; 
		width: 35%; 
		overflow: hidden; 
		overflow-x: scroll; 
		border-right: 1px solid #ccc; 
		border-left: 1px solid #ccc; 
		table {
			border-right: none; 
			border-left: none; 
			width: 100%; 
			th, 
			td {
				white-space: nowrap;
			}
		}
		td:last-child {
			border-bottom: 0;
		}
	}
	
	div.table-wrapper { 
		position: relative; 
		margin-bottom: 20px; 
		overflow: hidden; 
		border-right: 1px solid #ccc; 
		div.scrollable {
			margin-left: 35%;
			overflow: scroll;
			overflow-y: hidden;
		}
	}

	table.responsive { 
		margin-bottom: 0; 
		th, 
		td {
			position: relative;
			white-space: nowrap;
			overflow: hidden;
		}
	}

	table.responsive th:first-child,
	table.responsive td:first-child,
	table.responsive.pinned td { 
		display: none; 
	}	
}