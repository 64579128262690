// Content areas
a {

}
a:hover,
a:active,
a:focus {
	text-decoration: none;
}
h1.page-title {
	color: white;
	font-weight: normal;
	font-size: 36px;
	line-height: 44px;
	letter-spacing: 2px;
	margin: 125px 0 35px;
	@include bp(sm) {
		margin: 125px 0 32px;
	}
}
h2, .h2 {
	font-size: 36px;
	line-height: 44px;
	color: $teal;
	font-weight: 500;
	margin: 16px 0 14px;
}
h3, .h3 {
	font-size: 24px;
	line-height: 30px;
	color: $blue;
	font-weight: 500;
	margin: 14px 0 12px;
}
h4, .h4 {
	color: #4a4a4a;
	font-size: 20px;
	line-height: 30px;
	font-weight: 500;
	margin: 12px 0;
}
h5, .h5 {
	font-size: 16px;
	line-height: 24px;
	font-weight: 500;
	margin: 14px 0;
}
.intro-text {
	font-size: 20px;
	line-height: 30px;
}
hr {
	border-top-width: 2px;
	border-color: #eee;
	margin: 26px 0;
}
ul, ol { 
	margin: 0; 
	padding-left: 12px;
}
ul { 
	list-style-type: none; 
	font-size: 16px;
	line-height: 24px;
	li {
		display: flex;
		padding: 3px 0 6px;
		&:before {
			font-family: 'Font Awesome 5 Pro';
			font-weight: 600;
			content: '\f111';
			font-size: 6px;
			color: $teal;
			margin: 0 18px;
			position: relative;
		}
	}
	ul {
		margin: 0;
		padding-left: 25px;
		li {
			&:before {
				font-weight: 500;
			}
		}
		ul {
			li {
				&:before {
					font-weight: 600;
					content: '\f0c8';
				}
			}
		}
	}
}
.breadcrumb {
	font-size: 12px;
	font-weight: 500;
	letter-spacing: 1px;
	margin: 40px 0 30px;
	display: none;
	@include bp(sm) {
		display: block;
	}
	@include bp(md) {
		margin: 42px 0 30px;
	}
	.breadcrumb__entry {
		text-transform: uppercase;
		font-size: 12px;
		font-weight: 500;
		letter-spacing: 1px;
	}
}
li { 
	padding: 3px 0 0; 
}
> ul > li:first-child,
.col-1 > ul > li:first-child { 
	padding-top: 0; 
}
p { 
	margin-bottom: $base-padding; 
	font-size: 16px;
	line-height: 24px;
}
.small-text,
.table-smaller td.small-text,
.table-smaller th.small-text { font-size: 11px; }
.small-text p,
p.small-text { margin-bottom: 12px; }
blockquote {
	padding-left: 20px;
	color: $text-color-dark;
	font-weight: 600;
	margin: 25px 0;
	@include bp(sm) {
		padding-left: 10px;
		min-height: 30px;
		border-left: 2px solid #ccc;
		margin-left: 30px;
	}
}
blockquote :last-child { margin-bottom: 0; }
.wp-caption {
	padding: 5px 0;
	background: #f6f6f6;
	border: 1px solid #ddd;
	-webkit-border-radius: 4px;
	   -moz-border-radius: 4px;
	        border-radius: 4px;
}
.wp-caption img { display: block; margin: 0 auto; max-width: 100%; }
.wp-caption .wp-caption-text { 
	font-size: 12px; 
	text-align: center; 
	padding: 5px 5px 2px;
	line-height: 1.4;
}
.caption {
	@include bp-only(xs) {
		width: 100%;
		max-width: none !important;
		margin: 0 !important;
		img {
			width: 100%;
		}
	}
}
img.pull-right {
	@include bp-only(xs) {
		width: 100%;
		margin: 0 0 14px;
	}
	margin: 0 0 20px 20px;
}
img.pull-left {
	@include bp-only(xs) {
		width: 100%;
		margin: 0 0 14px;
	}
	margin: 0 20px 20px 0;
}
.quote {
	position: relative;
	color: $blue;
	font-size: 24px;
	line-height: 34px;
	font-style: italic;
	padding: 4px 0 20px 30px;
	margin: 22px 0;
	&:before {
		content: '';
		height: 100%;
		width: 3px;
		background: #eee;
		position: absolute;
		left: 0;
		top: 0;
	}
	@include bp(sm) {
		margin: 30px 0;
		padding: 14px 0 14px 30px;
	}
}
// Table styles from Bootstrap 3
// The .table class is not used - this is automatically applied.
table { background-color: transparent; }
th { text-align: left; }
table {
	width: 100%;
	max-width: 100%;
	margin-bottom: 20px;
}
table > thead, table > thead > tr > th {
	background: $teal;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
	padding: 16px 20px;
	line-height: 18px;
	vertical-align: top;
	border: 1px solid #ddd;
	font-size: 12px;
	color: #4a4a4a;
}
table > thead > tr > th, table > thead > tr > td, table > tbody > tr > th {
	color: #fff;
	font-weight: 600;
	border: 0;
}
table > thead > tr, table > tbody > tr:first-child {
	td {
		border-top: 1px solid $teal-dark;
	}
}
table > thead > tr > th, table > tbody > tr > th {
	vertical-align: bottom;
	border-left: 1px $teal-light solid;
	font-size: 16px;
	line-height: 19px;
	&:first-child {
		border-left: 1px $teal-dark solid;
	}
	&:last-child {
		border-right: 1px $teal-dark solid;
	}
}
table {
	border-collapse: collapse;
}
.bold-text {
	font-weight: 600 !important;
}
table > thead:first-child > tr:first-child > td:first-child { border-left: 1px solid $teal-dark; }
table > thead:first-child > tr:first-child > td:last-child { border-right: 1px solid $teal-dark; }
table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td { border-top: 1px solid $teal-dark; }
table > tbody { border-top: 1px solid $teal-dark; }
table table { background-color: #fff; }
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > th,
.table-condensed > tfoot > tr > th,
.table-condensed > thead > tr > td,
.table-condensed > tbody > tr > td,
.table-condensed > tfoot > tr > td { padding: 5px; }
.table-bordered { border: 1px solid #ddd; }
.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td { border: 1px solid #ddd; }
.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td { border-bottom-width: 2px; }
table > tbody > tr > td { background-color: #fff; }
.table-hover > tbody > tr:hover > td { background-color: #f5f5f5; }
.table-hover > tbody > tr > td.active:hover,
.table-hover > tbody > tr > th.active:hover,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr:hover > .active { background-color: #eee; }
.table-smaller th,
.table-smaller td { font-size: 0.9285em; }
@include bp('max-width: 767px') {
	.pinned {
		border: 0;
	}
}
