.classes {
	.page__hero {
		min-height: 200px;
		.page__hero__gradient {
			min-height: 200px;
		}
		.page-title {
			margin: 124px 0 32px;
		}
	}
	.news__search-cat {
		position: relative;
		margin-bottom: 88px;
		.news__cat {
			width: 100%;
			@include bp(sm) {
				width: calc(50% - 2px);
			}
			.news__cat--dd {
				margin: 0;
			}
			.news__cat--title {
				text-transform: uppercase;
				letter-spacing: 1px;
			}
			&:nth-child(1) {
				.news__cat--dd {
					margin-left: 20px;
					margin-bottom: 10px;
				}
			}
			&:nth-child(2) {
				.news__cat--title {
					margin-left: 20px;
					@include bp(sm) {
						margin-left: 0;
					}
				}
				.news__cat--dd {
					@include bp-only(xs) {
						margin-left: 20px;
					}
					@include bp(sm) {
						margin-right: 20px;
					}
				}
			}
			&.news__cat--class-2 {
				.news__cat--title {
					@include bp-only(xs) {
						margin-top: 11px;
					}
				}
			}
		}
		> .btn {
			margin: 20px 18px 22px 20px;
		}
		.reset {
			text-transform: uppercase;
			position: relative;
			font-size: 11px;
			line-height: 13px;
			color: #4a4a4a;
			font-weight: 700;
		}
		.adv-search__title {
			position: absolute;
			bottom: -48px;
			left: 0;
			width: 100%;
			height: 48px;
			background: #eee;
			text-align: center;
			padding: 12px;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: 1px;
			color: #4a4a4a;
			text-transform: uppercase;
			transition: $all-t;
			.far {
				margin-left: 13px;
				transition: $all-t;
			}
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: calc(100% - 40px);
				height: 2px;
				background: #ddd;
				margin: 0 20px;
			}
			&:hover, &:focus {
				background: #ddd;
				cursor: pointer;
				outline: none;
			}
		}
		.adv-search--active {
			.adv-search__title {
				.far {
					transform: rotate(45deg);
				}
			}
		}
		.adv-search__content {
			padding: 0 20px;
			.adv-search--dd {
				@include bp-only(xs) {
					padding: 10px 0 0;
				}
				@include bp(sm) {
				    width: calc(50% - 2px);
					display: inline-block;
					&.left {
						padding: 10px 15px 10px 0;
					}
					&.right {
						padding: 10px 0 10px 15px;
					}
				}
				.adv-search--title {
					display: block;
					margin-bottom: 8px;
					text-transform: uppercase;
					font-size: 12px;
					line-height: 18px;
					letter-spacing: 1px;
					position: relative;
					&:before {
				    	content: '\f0dc';
				    	font-family: 'Font Awesome 5 Pro';
				    	font-size: 16px;
				    	position: absolute;
				    	z-index: 1;
				    	font-weight: 600;
				    	top: calc(100% + 19px);
				    	right: 16px;
				    }
				}
				> select {
					display: block;
					width: 100%;
					height: 40px;
					border: 1px #ddd solid;
					background: white;
					border-radius: 2px;
					padding: 11px 15px 8px 11px;
					-moz-appearance: none; /* Firefox */
				    -webkit-appearance: none; /* Safari and Chrome */
				    appearance: none;
				    position: relative;
				}
			}
		}
	}
	.class-content {
		margin-bottom: 60px;
	}
	.class__listing {
		margin: 0 0 42px;
		@include bp(sm) {
			margin: 0 0 40px;
		}
		.class__results {
			display: block;
			color: #4a4a4a;
			font-size: 12px;
			line-height: 18px;
			margin: 0 0 -8px;
		}
		.class__entry {
			border-top: 4px $gold solid;
			margin-bottom: 30px;
			border-left: 1px solid #ddd;
			border-right: 1px solid #ddd;
			position: relative;
			padding: 0 20px;
			.class__entry__credits {
				display: inline-block;
				margin-bottom: 12px;
			    text-transform: uppercase;
			    color: white;
			    background: $blue;
			    font-size: 12px;
			    line-height: 18px;
			    font-weight: 400;
			    text-align: center;
			    padding: 6px 11px;
			    @include bp(sm) {
			    	margin-bottom: 0;
			    	position: absolute;
					top: 20px;
					right: 0;
			    }
			}
			.class__entry__title {
				width: calc(100% - 74px);
			}

			.class__entry__line {
				margin-bottom: 4px;
			}
			.class__entry__info {
				margin-bottom: 18px;
			}
			.class__entry__adv {
				.class__entry__info--adv {
					position: relative;
					padding-top: 18px;
					margin: 0 0 12px;
					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						height: 4px;
						width: 100%;
						background: #eee;
					}
				}
				.adv-class-title {
					text-align: center;
					background: #ddd;
					padding: 8px;
					position: relative;
					right: 20px;
					width: calc(100% + 40px);
					transition: $all-t;
					text-transform: uppercase;
					font-size: 16px;
					line-height: 24px;
					letter-spacing: 1px;
					color: $blue;
					.far {
						margin-left: 13px;
						transition: $all-t;
					}
					&:hover, &:focus {
						outline: none;
						background: darken(#ddd, 15%);
						cursor: pointer;
					}
				}
				&.class__entry__adv--active {
					.far {
						transform: rotate(45deg);
					}
				}
			}
		}
	}
	.media-contact {
		display: block;
		margin-bottom: 60px;
		@include bp(md) {
			margin-bottom: 30px;
		}
	}
	&.classes--landing {
		hr {
			margin: 16px 0;
		}
	}
}
.tinted-block {
	background: #eee;
	padding: 10px;
	padding-right: 14px;
	margin: 0 auto 30px;
	position: relative;
	max-width: 280px;
	min-height: 60px;
	display: block;
	transition: $all-t;
	@include bp(sm) {
		max-width: 355px;
		padding-top: 20px;
	}
	@include bp(sm) {
		padding: 20px;
	}
	@include bp(md) {
		max-width: none;
	}
	&:hover, &:focus {
		outline: none;
		background: #ddd;
	}
	.tb__icon {
		display: block;
		height: 40px;
		display: inline-block;
		margin-right: 10px;
		position: absolute;
		left: 10px;
		top: calc(50% - 20px);
	}
	span {
		font-size: 16px;
		line-height: 20px;
		font-weight: 700;
		color: $blue;
		width: calc(100%);
		display: inline-block;
		padding-left: 52px;
		@include bp(lg) {
			padding-left: 42px;
		}
	}
	&.tb--reg {
		@include bp(sm) {
			padding: 20px;
		}
		.tb__icon {
			left: 17px;
		}
		span {
			 
		}
	}
}
.blue {
	color: $blue;
}