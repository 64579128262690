.news {
	&.rte.news--landing .breadcrumb {
		@include bp(md) {
			margin: 40px 0 18px;
		}
		@include bp(lg) {
			margin: 42px 0 30px;
		}
	}
	.page__hero {
		min-height: 200px;
		.page__hero__gradient {
			min-height: 200px;
		}
		.page-title {
			margin: 124px 0 32px;
		}
	}
	.request-info {
		.rqi__img {
			background-image: url('../img/sidebar_submitNews.jpg');
		}
	}
	.news__search-cat {
		margin-top: 30px;
		background: #eee;
		@include bp(md) {
			margin-top: 0;
		}
		.news__cat {
		    position: relative;
		    min-height: 1px;
		    padding-left: 15px;
		    padding-right: 15px;

			@include bp(sm) {
				width: 41.66666667%;
				display: inline-block;
			}
			&.news__cat--active {
				.news__cat--dd .far:before {
					transform: rotate(180deg);
				}
				.news__cat--body {
					> li a {
						display: block;
					}
				}
			}
			.news__cat--body {
				padding: 0 17px 16px;
				display: block;
			    position: relative;
			    top: 100%;
			    left: 0px;
			    width: 100%;
			    z-index: 2;
				background: rgb(13, 129, 125);
				@include bp(sm) {
					position: absolute;
				}
				> li {
					list-style-type: none;
					margin: 4px 0;
					a {
						color: white;
						font-weight: 400;
						display: none;
						&:hover {
							color: #ddd;
						}
					}
				}
			}
			.news__cat--title, .news__cat--dd {
				margin-left: 20px;
			}
			.news__cat--title {
				display: block;
				font-size: 12px;
				line-height: 18px;
				color: #4a4a4a;
				font-weight: 400;
				margin: 16px 0 7px 20px;
			}
			.news__cat--dd {
				background: $teal;
				color: white;
				position: relative;
				margin-bottom: 20px;
				
				@include bp-only(xs) {
					width: calc(100% - 40px);
				}
				.news__cat--dd__title {
					display: block;
					padding: 15px 17px 16px;
					height: 50px;
					position: relative;
					font-size: 16px;
					line-height: 19px;
					outline: none;
					transition: $all-t;
					z-index: 1;
					color: white;
					font-weight: 400;
					&:before {
						content: '';
						position: absolute;
						z-index: -1;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: $teal;
						transition: $all-t;
					}
					&:focus {
						&:before {
							background: $teal-dark;
						}
					}
				}
				.far {
					position: absolute;
					z-index: 2;
					top: 20px;
					right: 20px;
					font-size: 22px;
					line-height: 14px;
					&:before {
						transition: $all-t;
						display: block;
					}
					&:after {
    					content: '';
    					position: absolute;
    					top: -5px;
    					left: -12px;
    					height: 24px;
    					width: 2px;
    					background: $teal-light;
    				}
				}
			}
		}
		.news__search {
			position: relative;
		    min-height: 1px;
		    padding-left: 15px;
		    padding-right: 15px;
		    
			@include bp(sm) {
				width: calc(58.33333333% - 6px);
				display: inline-block;
			}
			.news__cat--title {
				display: block;
				font-size: 12px;
				line-height: 18px;
				color: #4a4a4a;
				font-weight: 400;
				margin: 16px 0 7px;
			}
			.news__search--form {
				position: relative;
				.search__field {
					height: 52px;
				    width: calc(100% - 20px);
					position: relative;
					top: 0;
				    padding: 13px 54px 13px 20px;

				    &::-moz-placeholder {
						font-size: 16px;
						line-height: 24px;
						font-weight: 300;
						color: #9b9b9b;
						font-style: italic;
						opacity: 1;
					}
					&:-ms-input-placeholder {
						font-size: 16px;
						line-height: 24px;
						font-weight: 300;
						color: #9b9b9b;
						font-style: italic;
						opacity: 1;
					}
					&::-webkit-input-placeholder { 
						font-size: 16px;
						line-height: 24px;
						font-weight: 300;
						color: #9b9b9b;
						font-style: italic;
						opacity: 1;
					}
				}
				.fa-search {
		    		color: $blue;
		    		position: absolute;
	    		    top: 20px;
    				right: 18px;
    				&:after {
    					content: '';
    					position: absolute;
    					top: -5px;
    					left: -12px;
    					height: 24px;
    					width: 2px;
    					background: #eee;
    				}
    				@include bp(sm) {
    					right: 37px;
    				}
		    	}
			}
			@include bp-only(xs) {
				.news__cat--title, .news__search--form {
					margin: 0 20px;
					width: calc(100% - 40px);
					.search__field {
						width: 100%;
					}
				}
				.news__cat--title {
					margin: 0 20px 6px;
				}
				.news__search--form {
					margin-bottom: 20px;
				}
			}
		}
	}
	
	.news__listing {
		padding: 38px 0 14px;
		.news__entry {
			.news__entry__date {
				text-transform: uppercase;
				font-size: 12px;
				line-height: 18px;
				color: $teal;
			}
			.news__entry__title {
				transition: $all-t;
				margin: 8px 0 5px;
				&:hover {
					color: darken($blue, 15%);
				}
			}
			.fa-long-arrow-right {
				color: $gold;
			}
			.news__entry__excerpt {
				margin-bottom: 23px;
			}
			.news__entry__tags {
				margin-top: 4px;
				.news__entry__tags__tag {
					margin-right: 6px;
					font-size: 12px;
					color: #757575;
				}
			}
			.news__entry__img {
				display: none;
				@include bp(sm) {
					display: block;
					float: right;
					max-width: 146px;
					margin-top: 28px;
					margin-left: 24px;
				}
			}
			a {
				outline: none;
			}
		}
		hr {
			margin: 18px 0 30px;
			@include bp(lg) {
				margin: 26px 0 30px;
			}
		}
	}
	.pagination {
		margin-bottom: 72px;
		text-align: center;
		font-size: 20px;
		color: #757575;
		@include bp(sm) {
			margin-bottom: 60px;
		}
		@include bp(md) {
			text-align: left;
		}
		.pagination-btn {
			border-radius: 2px;
			height: 40px;
			width: 40px;
			background: #eee;
			font-size: 20px;
			transition: $all-t;
			text-align: center;
			&:hover {
				background: darken(#eee, 15%);
			}
			> span {
				position: relative;
				top: 5px;
			}
			&.prev-btn {
				float: left;
			}
			&.next-btn {
				float: right;
			}
			@include bp(sm) {
				display: inline-block;
				&.prev-btn {
					float: none;
					margin-right: 8px;
				}
				&.next-btn {
					float: none;
					margin-left: 28px;
				}
			}
		}
		.pagination-number {
			font-size: 20px;
			margin: 0 4px;
			color: #757575;
			transition: $all-t;
			font-weight: 400;
			text-align: center;
			&:hover {
				color: $blue;
			}
			&.number--active {
				color: $blue;
				position: relative;
				&:after {
					content: '';
					position: absolute;
					bottom: -2px;
					left: 0;
					width: 14px;
					height: 2px;
					background: $blue;
				}
			}
		}
	}
	&.news--detail {
		.news__entry__tags {
			margin: 38px 0;
			@include bp(sm) {
				margin: 36px 0 40px;
			}
			@include bp(md) {
				margin: 32px 0 44px;
			}
			@include bp(lg) {
				margin: 42px 0 44px;
			}
			.news__entry__tags__tag {
				margin-right: 6px;
				font-size: 12px;
				color: #757575;
			}
		}
		.news-post__seperator {
			margin: 21px 0 18px;
		}
	}
}
.rte .media-contact {
	display: none;
	@include bp(md) {
		display: block;
	}
	padding: 24px 36px;
	border: 1px solid #ddd;
	margin: 0 0 30px;
	h3 {
		color: #4a4a4a;
		margin: 0;
	}
	h4 {
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
		margin: -3px 0 5px;
	}
	hr {
		border-top-width: 4px;
		margin: 14px 0;
	}
	> a {
		display: block;
		font-size: 16px;
		line-height: 24px;
		.fa-phone, .fa-envelope {
			margin-right: 10px;
			color: $teal;
			font-size: 14px;
			line-height: 24px;
		}
		&.contact__phone-link {
			color: #4a4a4a;
			font-weight: 400;
		}
	}
	.contact__address, .contact-p2 {
		display: block;
		font-size: 16px;
		line-height: 24px;
		.fa-map-marker-alt {
			margin-right: 14px;
			color: $teal;
			font-size: 14px;
			line-height: 24px;
		}
	}
	.contact-p2 {
		margin-left: 27px;
	}
}