.interior {
	&.form--interior {
		.page__hero {
			.page-title {
				margin: 130px 0 32px;
				@include bp(sm) {
					margin: 124px 0 32px;
				}
			}
			.page__hero__gradient {
				min-height: 200px;
			}
		}
	}
	.page__hero {
		position: relative;
		background-color: $teal;
		&.page__hero--no-img {
			.page-title {
				margin: 125px 0 32px;
				@include bp(sm) {
					margin: 124px 0 32px;
				}
			}
		}
		// Background gradient (hide behind featured image to use as a back-up background)
		.page__hero__gradient {
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background: rgb(0,19,135); /* Old browsers */
			background: -moz-linear-gradient(left, rgba(0,19,135,1) 0%, rgba(14,129,125,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left, rgba(0,19,135,1) 0%,rgba(14,129,125,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right, rgba(0,19,135,1) 0%,rgba(14,129,125,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001387', endColorstr='#0e817d',GradientType=1 ); /* IE6-9 */
			min-height: 200px;
		}
		.page__hero__dots {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			opacity: 0.1;
			display: none;
			@include bp(sm) {
				display: block;
			}
			&:before {
				position: absolute;
				content: '';
				top: 0;
				left: 0;
				height: 100%;
				width: 50%;
				background-image: url('../img/dot_background-left.png');
				background-size: auto;
				// top-left position is default
				background-repeat-x: no-repeat;
				max-width: 380px;
			}
			&:after {
				position: absolute;
				content: '';
				top: 0;
				right: 0;
				height: 100%;
				width: 50%;
				background-image: url('../img/dot_background.png');
				background-size: auto;
				background-position: right top;
				-webkit-background-repeat: no-repeat;
				background-repeat-x: no-repeat;
				max-width: 380px;
			}
		}
		.page__hero__img {
			position: absolute;
			z-index: 0;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			min-height: 350px;
			&:before {
				content: '';
				position: absolute;
				z-index: 1;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background-image: url('../img/stock/iStock-646863134.jpg');
				background-size: cover;
				background-position: top;
			}
			&:after {
				content: '';
				position: absolute;
				z-index: 2;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#001387+0,074a82+50,0e817d+100&0.75+0,0.5+50,0+100 */
				background: -moz-linear-gradient(left, rgba(0,19,135,1.0) 0%, rgba(7,74,130,0.5) 50%, rgba(14,129,125,0) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left, rgba(0,19,135,1.0) 0%,rgba(7,74,130,0.5) 50%,rgba(14,129,125,0) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right, rgba(0,19,135,1.0) 0%,rgba(7,74,130,0.5) 50%,rgba(14,129,125,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf001387', endColorstr='#000e817d',GradientType=1 ); /* IE6-9 */
			}
		}
		.page-title {
			margin: 274px 0 32px;
			@include bp(md) {
				margin: 272px 0 32px;
			}
		}
	}
	.internal-content {
		margin-bottom: 50px;
		@include bp(md) {
			margin-bottom: 84px;
		}
	}
	.request-info {
		background: $blue;
		position: relative;
		margin-bottom: 56px;
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			right: 0;
			background-image: url(../img/dots_pattern_sidebar.png);
			background-size: auto;
			background-position: right bottom;
			-webkit-background-repeat: no-repeat;
			background-repeat-x: no-repeat;
			width: 100%;
			height: 128px;
		}
		.rqi__img {
			width: 100%;
			height: 170px;
			background-image: url(../img/sidebar_getInfo.jpg);
			position: relative;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background: -moz-linear-gradient(left, rgba(0,19,135,1) 0%, rgba(14,129,125,1) 100%); /* FF3.6-15 */
				background: -webkit-linear-gradient(left, rgba(0,19,135,1) 0%,rgba(14,129,125,1) 100%); /* Chrome10-25,Safari5.1-6 */
				background: linear-gradient(to right, rgba(0,19,135,1) 0%,rgba(14,129,125,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001387', endColorstr='#0e817d',GradientType=1 ); /* IE6-9 */
				opacity: 0.5;
			}
		}
		.rqi__title {
			color: white;
			font-size: 24px;
			line-height: 30px;
			margin: 23px 36px 0;
			text-align: center;
			font-weight: 400;
			&:after {
				content: '';
				display: block;
				width: 230px;
				height: 4px;
				background: $light-blue;
				margin: 14px auto 11px;
			}
		}
		.rqi__body {
			font-weight: 400;
			margin: 0 34px;
			font-size: 16px;
			line-height: 24px;
			color: white;
			text-align: center;
		}
		.btn-container {
			text-align: center;
			position: relative;
			z-index: 2;
			.btn {
				font-weight: 700;
				margin: 22px 0 34px;
			}
		}
		@include bp-only(sm) {
			max-width: 605px;
			margin: 0 auto 60px;
		    position: relative;
    		z-index: 2;
    		.rqi__img {
    			width: 310px;
    			height: 170px;
    			float: left;
    			margin: 20px 20px 40px 20px;
    		}
    		.rqi__title {
    			text-align: left;
    			padding: 20px 25px 0;
    			margin: 0;
    			&:after {
    				width: auto;
    				display: flex;
				    margin: 14px 25px 11px;
    			}
    		}
    		.rqi__body {
    			text-align: left;
    			margin: 0 24px;
    		}
    		.btn-container {
    			text-align: left;
    		}
		}
	}
	.captcha-placeholder {
		position: relative;
		right: 8px;
		bottom: 8px;
		margin-bottom: -10px;
	}
	.page__form {
		margin: 0 0 60px;
	}
}